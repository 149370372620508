import {menuTree} from "./MenuTree";

const Menu = () => {
  let roles = JSON.parse(localStorage.getItem('user')).user?.roles;
  const resellerCode = JSON.parse(localStorage.getItem('user')).user?.loginReseller?.code;

  function hasIntersect(_array1, _array2) {
    return _array1?.some(r => _array2?.includes(r));
  }

  function filterByRole(_menuTree, _roles) {
    let filteredTree = [];
    for (let parentIndex = 0; parentIndex < _menuTree.length; parentIndex++) {
      let currentParentNode = _menuTree[parentIndex];
      let copyParentNode;
      if (hasIntersect(currentParentNode?.roles, _roles)) {
        if (currentParentNode?.items) {
          copyParentNode = {...currentParentNode, items: []};
        } else {
          copyParentNode = {...currentParentNode};
          filteredTree?.push(copyParentNode);
          // child yoksa devam etme
          continue;
        }
      } else {
        continue;
      }

      for (let childIndex = 0; childIndex < _menuTree[parentIndex].items?.length; childIndex++) {

        let currentChildNode = _menuTree[parentIndex].items[childIndex];
        if (hasIntersect(currentChildNode?.roles, _roles)) {
          copyParentNode?.items.push(currentChildNode);
        }
      }
      if(copyParentNode.label != "Sipariş Yönetimi" || resellerCode == 1500346 || resellerCode == 4014119 || resellerCode == 4010476 || resellerCode == 501485 || resellerCode == 64522 || resellerCode == 7000024 || resellerCode == 507883 || resellerCode == 4039554 || resellerCode == 4049002 || resellerCode == 501677 || resellerCode == 1500758 || resellerCode == 4046670 || resellerCode == 4053092 || resellerCode == 4100021){
        filteredTree.push(copyParentNode);
      }
    }
    return filteredTree;
  }

  return [
    {
      items: [
        {label: "Dashboard", icon: "pi pi-fw pi-home", to: "/dashboard"},
      ],
    },
    {
      items: filterByRole(menuTree, roles)
    }
  ];
};

export default Menu;
