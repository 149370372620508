import React, { useEffect, useRef, useState } from "react";
import ResellerOrderService from "./httpService";
import { handleError } from "../../service/HandleErrorService";
import { Card } from "primereact/card";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Rating } from "primereact/rating";
import { Button } from "primereact/button";
import { Galleria } from "primereact/galleria";
import { Paginator } from "primereact/paginator";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useHistory, useLocation } from "react-router-dom";
import "./DataView.css";
import CategoryService from "../categorymanagement/httpService";
import CanSellService from "../canSell/httpService";
import { InputNumber } from "primereact/inputnumber";
import { Badge } from "primereact/badge";
import { BannerService } from "../banner/BannerService";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Sidebar } from "primereact/sidebar";
import Bestsellers from "../../assets/images/bestsellers.png";
import New from "../../assets/images/new.png";
import ProductService from "../product/httpService";
import { Chip } from "primereact/chip";
import { InputTextarea } from "primereact/inputtextarea";

const ResellerProductsManagement = () => {
  const resellerCode = JSON.parse(localStorage.getItem('user')).user?.loginReseller?.code;
  const history = useHistory();
  const location = useLocation();
  const [completeLoading, setCompleteLoading] = useState(false);
  const [visibleBasket, setVisibleBasket] = useState(false);
  const [itemCount, setItemCount] = useState(0);

  const [searchRequest, setSearchRequest] = useState({});
  const [sort, setSort] = useState({});
  const [detailSearch, setDetailSearch] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const services = new ResellerOrderService();
  const toast = useRef(null);
  const [layout, setLayout] = useState("grid");
  const [loading, setLoading] = useState(true);
  const rows = useRef(6);
  const [first, setFirst] = useState(0);
  const isMounted = useRef(false);
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(12);
  const [basicPage, setBasicPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(10);
  const [allSubCategoryByCategory, setAllSubCategoryByCategory] = useState([]);
  const [cartShowDialog, setCartShowDialog] = useState(false);
  const [activeResellerProductOrder, setActiveResellerProductOrder] = useState({
    resellerProductOrderDetails: [],
    totalPrice: 0,
  });
  const [allCategories, setAllCategories] = useState([]);
  const categoryService = new CategoryService();
  const [allBrand, setAllBrand] = useState([]);
  const canSellService = new CanSellService();
  const [empty, setEmpty] = useState(null);
  const [btnClassLabelDashBoard, setBtnClassLabelDashBoard] =
    useState("p-button-success");
  const [btnClassLabelNew, setBtnClassLabelNew] = useState(null);
  const [btnClassLabelBestSell, setBtnClassLabelBestSell] = useState(null);
  const bannerService = new BannerService();
  const [currentBanner, setCurrentBanner] = useState([]);
  const [allStockType, setAllStockType] = useState([]);
  const [labelType, setLabelType] = useState([]);
  const [label, setLabel] = useState(location?.state?.label || "");
  const [editCartShow, setEditCartShow] = useState(false);
  const [editProductOrderDetail, setEditProductOrderDetail] = useState({});
  const enumService = new CategoryService();
  const [currentPrice, setCurrentPrice] = useState(0);
  const [changeFilter, setChangeFilter] = useState(false);
  const productService = new ProductService();
  const resellerCodes = [1500346,4014119,4010476,501485,64522,7000024,507883,4039554,4049002,501677,1500758,4046670,4053092,4100021]

  const sortData = (data) => {
    const x = data?.sort(function(a, b){
    if(a.name < b.name) { return -1; }
    if(a.name > b.name) { return 1; }
    return 0;
})
return x;
}

  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 5,
    },
    {
      breakpoint: "768px",
      numVisible: 3,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
    },
  ];

  useEffect(() => {
    setCurrentPrice(
      editProductOrderDetail?.quantity * editProductOrderDetail?.price
    );
  }, [editProductOrderDetail?.quantity]);

  // useEffect(() => {
  //   getAllProducts(searchRequest, basicRows, basicPage, label);
  // }, [changeFilter]);

  useEffect(() => {
    if (resellerCode != 1500346 && resellerCode != 4014119 && resellerCode != 4010476 && resellerCode != 501485 && resellerCode != 64522 && resellerCode != 7000024 && resellerCode != 507883 && resellerCode != 4039554 && resellerCode != 4049002 && resellerCode != 501677 && resellerCode != 1500758 && resellerCode != 4046670 && resellerCode != 4053092 && resellerCode != 4100021){
      history.push("/dashboard");
    }
    // if (resellerCodes.includes(resellerCode)){
    //   history.push("/dashboard");
    // }

    categoryService
      .getAll()
      .then((res) => {
        setAllCategories(sortData(res));
      })
      .catch(handleError);
    canSellService.getAllBrand().then(setAllBrand);

    enumService
      .getStockType()
      .then((res) => {
        setAllStockType(res);
      })
      .catch((err) => handleError(err, toast));

    services
      .getLabelType()
      .then((res) => {
        res?.forEach((value) => {});
        setLabelType(res);
        getAllProducts(searchRequest, basicRows, basicPage, label, res);
      })
      .catch((err) => handleError(err, toast));
    getBannerService();

    categoryService
      .getAllSubCategories()
      .then((res) => {
        setAllSubCategoryByCategory(sortData(res));
      })
      .catch((err) => handleError(err, toast));
  }, []);

  const getBannerService = () => {
    bannerService
      .currentBanner()
      .then((res) => {
        setCurrentBanner(res);
      })
      .catch((err) => handleError(err, toast));
  };
  useEffect(() => {
    if (isMounted.current) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAllProducts = (searchRequest, size, page, label, type) => {
    setLoading(true);

    if (label) {
      let _label =
        type !== undefined
          ? type?.find((value) => value?.value === label)
          : labelType?.find((value) => value?.value === label);
      searchRequest.labelStatus = _label?.key;
    }
    services
      .getAllProducts(searchRequest, size, page)
      .then((res) => {
        let _pageState = {};
        setAllProducts(res?.content);
        setTotalRecords(res?.totalElements);
        setBasicRows(res.size);
        setBasicPage(page);
        setFirst(size * page);
      })
      .catch((err) => {
        handleError(err, toast);
      })
      .finally(() => setLoading(false));
    setEmpty(label);
  };

  const getAllProductsSearch = (searchRequest, size, page) => {
    setLoading(true);
    delete searchRequest["labelStatus"]
    services
      .getAllProducts(searchRequest, size, page)
      .then((res) => {
        let _pageState = {};
        setAllProducts(res?.content);
        setTotalRecords(res?.totalElements);
        setBasicRows(res.size);
        setBasicPage(page);
        setFirst(size * page);
      })
      .catch((err) => {
        handleError(err, toast);
      })
      .finally(() => setLoading(false));
      setEmpty("Tüm Ürünler")
  };

  const getActiveResellerProductOrder = () => {
    services
      .getResellerProductCart(0)
      .then((res) => {
        setActiveResellerProductOrder(res);
        setItemCount(res?.itemCount);
      })
      .catch((err) => handleError(err, toast));
  };
  useEffect(() => {
    getActiveResellerProductOrder();
  }, []);

  const itemTemplate2 = (item) => {
    return (
      <img
        src={item?.baseUrl}
        onError={(e) =>
          (e.target.src =
            "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
        }
      />
    );
  };

  const thumbnailTemplate2 = (item) => {
    return <img src={item?.baseUrl} />;
  };

  const stockStatusCheck = (status) => {
    if (status === "AVAILABLE") return "instock";
    if (status === "NONE") return "outofstock";
  };

  const labelCheck = (item) => {
    const labels = [];
    item?.model?.labels?.map((item) => labels.push(item?.labelStatus));
    if (
      labels.includes("NEW_PRODUCT") &&
      !labels.includes("SELL_BEST_PRODUCT")
    ) {
      return (
        <div style={{ position: "relative" }}>
          <img src={New} className="new" />
        </div>
      );
    }
    if (
      labels.includes("SELL_BEST_PRODUCT") &&
      !labels.includes("NEW_PRODUCT")
    ) {
      return (
        <div style={{ position: "relative" }}>
          <img src={Bestsellers} className="best-sellers" />
        </div>
      );
    }
    if (labels.includes("NEW_PRODUCT" && "SELL_BEST_PRODUCT")) {
      return (
        <div style={{ position: "relative" }}>
          <img src={New} className="new" />
          <img src={Bestsellers} className="new-best-sellers" />
        </div>
      );
    }
  };

  const convertParams = (params) => {
    switch (params) {
      case "category":
        return "Kategori";
      case "subCategory":
        return "Alt Kategori";
      case "brand":
        return "Marka";
      case "barcode":
        return "Barkod";
      case "supplyStatus":
        return "Tedarikçi Durumu";
      case "origin":
        return "Menşei";
      case "minPrice":
        return "Min Fiyat";
      case "maxPrice":
        return "Max Fiyat";
      case "stockStatus":
        return "Stok Durumu";
      default:
        break;
    }
  };

  const renderGridItem = (item) => {
    return (
      <div className="p-col-12 p-md-3">
        <div
          className="product-grid-item hover-item"
          style={{ borderRadius: 15 }}
          // onClick={(e) => history.push("resellerProduct/" + item?.model.id)}
        >
          {labelCheck(item)}

          {/* <div>
              <i className="pi pi-tag product-category-icon"></i>
              <span className="product-category">
                {item?.model?.subCategory.category?.name}-{">"}
                {item?.model?.subCategory?.name}
              </span>

            </div> */}

          <div className="p-grid p-dir-col p-ai-center">
            <span
              className={`product-badge ${stockStatusCheck(
                item?.stockStatus
              )} p-as-end`}
            >
              {item?.stockStatusLabel}
            </span>
            <img
              src={
                item?.model?.pictures?.[0]?.baseUrl ||
                "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png"
              }
              className="p-mt-2 p-mb-2"
              onError={(e) =>
                (e.target.src =
                  "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
              }
            />
          </div>

          <div className="product-name">{item?.model?.name}</div>
          <div className="product-description">
            <i
              className="pi pi-tag product-category-icon"
              style={{ color: "orange" }}
            ></i>
            <span className="product-category">
              {item?.model?.subCategory.category?.name}-{">"}
              {item?.model?.subCategory?.name}
            </span>
          </div>
          <div className="product-grid-item-bottom">
            <div>
              <span className="product-price" style={{ fontSize: "0.9rem" }}>
                Adet:
              </span>

              <InputNumber
                min={0}
                style={{ width: 45 }}
                className="p-ml-2"
                max={10000}
                value={item?.quantity}
                onChange={(e) => {
                  const data = [];
                  data.push(
                    ...allProducts?.map((items) => {
                      return {
                        ...items,
                        quantity:
                          items?.model?.id === item?.model?.id ? e?.value : 0,
                      };
                    })
                  );

                  setAllProducts(data);
                }}
              />
            </div>
            <span className="product-price">
              {item?.modelPriceDisty?.resellerPrice} ₺
            </span>
          </div>
          <div className="p-col-12 p-md-12 p-mt-1">
            <Button
              icon="pi pi-shopping-cart"
              className="p-mt-2"
              style={{
                backgroundColor: item?.reserveStatus == "RESERVED" && "#3adb76",
                borderWidth: item?.reserveStatus == "RESERVED" && 0,
              }}
              label="Sepete Ekle"
              disabled={item?.stockStatus === "NONE"}
              onClick={(e) => {
                services
                  .resellerCreateProductOrder({
                    modelId: item?.model?.id,
                    quantity: item?.quantity,
                    barcode: item?.model?.barcode,
                  })
                  .then((res) => {
                    toast?.current?.show({
                      severity: "success",
                      detail: "Ürün sepette Eklendi.",
                    });
                    getActiveResellerProductOrder();
                    getAllProducts(searchRequest, basicRows, 0);
                  })
                  .catch((err) => handleError(err, toast));
              }}
            ></Button>
            <div className="template p-mt-2">
              <Button
                className="detail p-p-0"
                onClick={(e) => {
                  history.push({
                    pathname:
                      "resellerProduct/" +
                      item?.model.id +
                      "/" +
                      item.model.barcode,
                    state: { label: label ? label : "Ana Sayfa" },
                  });
                }}
              >
                <i className="pi pi-chevron-right p-px-2" />
                <span className="p-px-3">Ürün Detayı</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      // <div className="p-col-12 p-md-2">
      //   <div className="product-grid-item card">
      //     <div className="">
      //       <div>
      //         <Button
      //           className=" p-button-sm  p-button-text"
      //           onClick={(e) =>
      //             history.push("resellerProduct/" + data?.model.id)
      //           }
      //           style={{ fontSize: 14 }}
      //         >
      //           {data?.model?.subCategory.category?.name}-{">"}
      //           {data?.model?.subCategory?.name}
      //         </Button>
      //       </div>
      //       <Badge
      //         value={data?.stockStatusLabel}
      //         size="normal"
      //         severity={data?.severity}
      //         className="bp-badge-success"
      //       />
      //     </div>
      //     <div></div>
      //     <div className="product-grid-item-content">
      //       {data?.model?.pictures ? (
      //         <Galleria
      //           className="img"
      //           value={data?.model?.pictures}
      //           responsiveOptions={responsiveOptions}
      //           numVisible={1}
      //           circular
      //           style={{ maxWidth: "640px" }}
      //           showItemNavigators
      //           showThumbnails={false}
      //           item={itemTemplate2}
      //           thumbnail={thumbnailTemplate2}
      //         />
      //       ) : (
      //         <img
      //           src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png"
      //           onError={(e) =>
      //             (e.target.src =
      //               "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
      //           }
      //         />
      //       )}

      //       <div className="product-name" style={{ fontSize: 13 }}>
      //         <p>{data.model.name}</p>
      //       </div>
      //     </div>
      //     <div className="product-grid-item-bottom">
      //       <span style={{ fontSize: 16 }} className="product-price">
      //         {data?.modelPriceDisty?.resellerPrice}₺
      //       </span>
      //       <div>
      //         <InputNumber
      //           min={0}
      //           max={10000}
      //           value={currentQuantity}
      //           onChange={(e) => setCurrentQuantity(e.value)}
      //         />
      //         <Button
      //           icon="pi pi-shopping-cart"
      //           className="p-button-rounded p-button-text"
      //           label="Sepete Ekle"
      //           /* disabled={data.stockStatus==='NONE'}*/
      //           onClick={(e) => {
      //             services
      //               .resellerCreateProductOrder({
      //                 modelId: data?.model?.id,
      //                 quantity: currentQuantity,
      //                 barcode: data?.model?.barcode,
      //               })
      //               .then((res) => {
      //                 toast?.current?.show({
      //                   severity: "success",
      //                   detail: "Ürün sepette Eklenmiştir.",
      //                 });
      //               });
      //           }}
      //         />
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  };
  const itemTemplate = (product) => {
    if (!product) {
      return;
    }
    return renderGridItem(product);
  };

  const onBasicPageChange = (event) => {
    setBasicFirst(event.first);
    setBasicRows(event.rows);

    getAllProducts(searchRequest, event.rows, event.page, label);
  };

  const cartHeader = () => {
    let _activeResellerProductCart = { ...activeResellerProductOrder };
    return (
      <>
        <h5>
          <u>Sepet Bilgisi</u>
        </h5>
        <div>
          <p>
            Toplam Adet:
            {_activeResellerProductCart?.totalQuantity
              ? _activeResellerProductCart?.totalQuantity
              : 0}
          </p>

          <p>
            Toplam Fiyat:
            {_activeResellerProductCart?.totalPrice
              ? _activeResellerProductCart.totalPrice
              : 0}
            ₺
          </p>
        </div>
      </>
    );
  };

  const takeOutItem = (item) => {
    services
      .deleteResellerProductDetailCart({
        resellerProductDetailId: item?.id,
        modelId: item?.model?.id,
      })
      .then((res) => {
        toast?.current?.show({
          severity: "success",
          detail: "Ürün sepetten çıkarıldı.",
        });

        getActiveResellerProductOrder();
        getAllProducts(searchRequest, basicRows, 0);
      })
      .catch((err) => handleError(err, toast));
  };

  const imageItemTemplate = (item) => {
    return (
      <>
        <img
          src={item?.image?.publicAddress}
          alt={item.alt}
          style={{ width: "50%", display: "block" }}
        />
      </>
    );
  };

  const thumbnailTemplate = (item) => {
    return (
      <img
        src={item.thumbnailImageSrc}
        alt={item.alt}
        style={{ display: "block" }}
      />
    );
  };

  const completeSale = () => {
    setCompleteLoading(true);
    services
      .completedResellerProductCart(activeResellerProductOrder?.id)
      .then((res) => {
        setVisibleBasket(false);
        setActiveResellerProductOrder({});
        toast?.current?.show({
          severity: "success",
          detail: "Sipariş Tamamlandı.",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((err) => handleError(err, toast))
      .finally(() => setCompleteLoading(false));
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="">
        <div className="p-mb-2 p-lg-2">
          <Sidebar
            visible={detailSearch}
            onHide={() => setDetailSearch(false)}
            position="left"
          >
            <div className="p-fluid p-mt-5">
              <h5>Detaylı Arama</h5>
              <div className="p-field">
                <label htmlFor="category" className="p-sr-only">
                  Kategori
                </label>
                <Dropdown
                  value={searchRequest?.category}
                  options={allCategories}
                  filter
                  filterMatchMode="contains"
                  filterPlaceholder="Kategori Ara"
                  optionLabel="name"
                  optionValue="name"
                  placeholder="Kategori"
                  showClear
                  id="categoryName"
                  onChange={(e) => {
                    let _searchRequest = { ...searchRequest };
                    _searchRequest.category = e.target.value;
                    _searchRequest.subCategory=undefined;
                    setSearchRequest(_searchRequest);
                    if (e.target.value) {
                      productService
                        .getSubCategoryByCategory({ name: e.target.value })
                        .then((res) => {
                          setAllSubCategoryByCategory(res);

                        })
                        .catch((err) => handleError(err, toast));
                    } else {
                      categoryService
                        .getAllSubCategories()
                        .then((res) => setAllSubCategoryByCategory(res))
                        .catch((err) => handleError(err, toast));
                    }
                  }}
                />
              </div>
              <div className="p-field">
                <label htmlFor="subCategory" className="p-sr-only">
                  Alt Kategori
                </label>
                <Dropdown
                  id="subCategory"
                  value={searchRequest.subCategory}
                  options={allSubCategoryByCategory}
                  placeholder="Alt Kategori"
                  filter
                  filterMatchMode="contains"
                  showClear
                  filterPlaceholder="Alt Kategori Ara"
                  optionValue="name"
                  optionLabel="name"
                  onChange={(e) => {
                    let _searchRequest = { ...searchRequest };
                    _searchRequest.subCategory = e.target.value;
                    setSearchRequest(_searchRequest);
                  }}
                />
              </div>
              <div className="p-field">
                <Dropdown
                  id="brand"
                  value={searchRequest.brand}
                  options={allBrand}
                  showClear
                  placeholder="Marka"
                  optionLabel="name"
                  optionValue="name"
                  onChange={(e) => {
                    let _searchRequest = { ...searchRequest };
                    _searchRequest.brand = e.target.value;
                    setSearchRequest(_searchRequest);
                  }}
                />
              </div>

              <div className="p-field">
                <label htmlFor="barcode" className="p-sr-only">
                  Barkod
                </label>
                <InputText
                  id="barcode"
                  value={searchRequest.barcode}
                  type="text"
                  placeholder="Barkod"
                  onChange={(e) => {
                    let _searchRequest = { ...searchRequest };
                    _searchRequest.barcode = e.target.value;
                    setSearchRequest(_searchRequest);
                  }}
                />
              </div>

              <div className="p-field">
                <label htmlFor="supply" className="p-sr-only">
                  Tedarikçi Durumu
                </label>
                <Dropdown
                  id="supply"
                  value={searchRequest.supplyStatus}
                  options={["AÇIK", "KAPALI"]}
                  placeholder="Tedarikçi Durumu"
                  showClear
                  onChange={(e) => {
                    let _searchRequest = { ...searchRequest };
                    _searchRequest.supplyStatus = e.target.value;
                    setSearchRequest(_searchRequest);
                  }}
                />
              </div>

              <div className="p-field">
                <label htmlFor="origin" className="p-sr-only">
                  Menşei
                </label>
                <InputText
                  id="origin"
                  value={searchRequest.origin}
                  type="text"
                  placeholder="Menşei"
                  onChange={(e) => {
                    let _searchRequest = { ...searchRequest };
                    _searchRequest.origin = e.target.value;
                    setSearchRequest(_searchRequest);
                  }}
                />
              </div>

              <div className="p-field">
                <InputText
                  type="number"
                  min={0}
                  max={100000}
                  value={searchRequest.minPrice}
                  placeholder="Min Fiyat"
                  onChange={(e) => {
                    let _searchRequest = { ...searchRequest };
                    _searchRequest.minPrice = e.target.value;
                    setSearchRequest(_searchRequest);
                  }}
                />
              </div>
              <div className="p-field">
                <InputText
                  type="number"
                  min={0}
                  max={100000}
                  value={searchRequest.maxPrice}
                  placeholder="Max Fiyat"
                  onChange={(e) => {
                    let _searchRequest = { ...searchRequest };
                    _searchRequest.maxPrice = e.target.value;
                    setSearchRequest(_searchRequest);
                  }}
                />
              </div>

              <Dropdown
                id="stockStatus"
                value={searchRequest.stockStatus}
                options={allStockType}
                optionLabel="value"
                optionValue="key"
                placeholder="Stok Durumu"
                showClear
                onChange={(e) => {
                  let _searchRequest = { ...searchRequest };
                  _searchRequest.stockStatus = e.target.value;
                  setSearchRequest(_searchRequest);
                }}
              />

              <br />
              <Button
                type="button"
                label="Ara"
                onClick={(e) => {
                  setLabel("")
                  setLoading(true);
                  getAllProductsSearch(searchRequest, basicRows, 0);
                  setDetailSearch(false);
                }}
              />
            </div>
          </Sidebar>
        </div>
        <Sidebar
          visible={visibleBasket}
          className="basketSideBar"
          position="right"
          onHide={() => setVisibleBasket(false)}
          style={{ width: 340 }}
          icons={() => (
            <div className="basket-header">
              <i
                style={{
                  fontSize: "1.3rem",
                  color: "orange",
                  fontWeight: "bold",
                }}
                className="pi pi-shopping-cart p-mr-2"
              ></i>

              <div
                style={{
                  fontSize: "1.2rem",
                  color: "orange",
                  fontWeight: "bold",
                }}
              >
                SEPETTEKİ ÜRÜNLER
              </div>
            </div>
          )}
        >
          <div
            style={{ overflow: "scroll", height: "85%", overflowX: "hidden" }}
          >
            <div className="basket p-pt-2" style={{ height: "100%" }}>
              {activeResellerProductOrder?.resellerProductOrderDetails?.map(
                (item) => (
                  <div
                    key={item?.model?.id}
                    className="p-fluid p-formgrid p-grid"
                  >
                    <div className="p-field p-col-12 p-md-3">
                      <img
                        src={`showcase/demo/images/product/${item?.image}`}
                        style={{ width: 70, height: 70 }}
                        onError={(e) =>
                          (e.target.src =
                            "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
                        }
                        alt={item?.model?.name}
                      />
                    </div>
                    <div
                      className="p-field p-col-12 p-md-7"
                      style={{ fontSize: "0.9rem", color: "#000" }}
                    >
                      <div>{item?.model?.name}</div>

                      <div className="p-mt-2" style={{ color: "#5D5D5D" }}>
                        Adet: {item?.quantity}
                      </div>
                    </div>
                    <div
                      className="p-field p-col-12 p-md-1"
                      onClick={() => takeOutItem(item)}
                    >
                      <i
                        id="basket-delete-icon"
                        className="pi pi-trash basket-delete-icon p-p-2"
                        style={{
                          fontSize: "1.3rem",
                          color: "red",
                        }}
                      ></i>
                    </div>
                    <div
                      className="p-field p-col-12 p-md-12 p-d-flex"
                      style={{ position: "relative" }}
                    >
                      <div style={{ fontSize: "0.8rem", color: "#999" }}>
                        {item?.model?.barcode}
                      </div>
                      <div
                        style={{
                          color: "#2196f3",
                          position: "absolute",
                          bottom: 0,
                          right: 30,
                          fontWeight: "bold",
                        }}
                      >
                        {item?.price} TL
                      </div>
                    </div>

                    <div
                      className="p-field p-col-12 p-md-12"
                      style={{
                        height: 1,
                        backgroundColor: "black",
                        opacity: 0.2,
                      }}
                    ></div>
                  </div>
                )
              )}
            </div>
            <div>
              <div
                style={{ position: "fixed", bottom: 0 }}
                className="p-fluid p-formgrid p-grid p-js-center p-ai-center "
              >
                <div className="p-field p-col-12 p-md-12">
                  <div style={{ fontWeight: "bold" }}>
                    TOPLAM: {activeResellerProductOrder?.totalPrice} TL
                  </div>
                </div>
                <div className="p-field p-col-12 p-md-8 ">
                  <Button
                    label="Siparişi Tamamla"
                    loading={completeLoading}
                    className={`p-button-raised ${btnClassLabelDashBoard}`}
                    disabled={
                      !activeResellerProductOrder?.resellerProductOrderDetails
                        ?.length > 0
                    }
                    onClick={() => {
                      completeSale();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Sidebar>
        <div className="p-fluid p-formgrid p-grid p-mr-3 p-ml-3 p-ai-center">
          <div className="p-field p-col-12 p-md-2">
            <Button
              label="Detaylı Arama"
              className={`p-button-raised  p-button-warning`}
              style={{ paddingBlock: 10 }}
              icon={"pi pi-filter"}
              onClick={(e) => {
                setDetailSearch(true);
              }}
            />
          </div>
          <div className="p-field p-col-12 p-md-5 p-d-flex">
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                style={{
                  paddingBlock: 10,
                  fontSize: "1.2rem",
                  borderTopLeftRadius: 7,
                  borderBottomLeftRadius: 7,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
                placeholder="Ürün Ara..."
                value={searchRequest?.modelName}
                onChange={(e) => {
                  let _searchRequest = { ...searchRequest };
                  _searchRequest.modelName = e.target.value;
                  setSearchRequest(_searchRequest);
                }}
              />
            </span>

            <Button
              style={{
                paddingBlock: 10,
                borderTopRightRadius: 7,
                borderBottomRightRadius: 7,
                maxWidth: 100,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
              label="Ara"
              icon="pi pi-search"
              className="p-button-warning"
              onClick={() => {
                setLabel("")
                setLoading(true);
                getAllProductsSearch(searchRequest, basicRows, 0);
              }}
            />
          </div>

          <div className="p-field p-col-12 p-md-2">
            <div>
              <Button
                style={{ paddingBlock: 10 }}
                icon="pi pi-shopping-cart"
                className="p-justify-end"
                label="Sepetim"
                onClick={(e) => {
                  setVisibleBasket(true);
                }}
              >
                <Badge value={itemCount}></Badge>
              </Button>
            </div>
          </div>
        </div>

        <div className="p-fluid p-formgrid p-grid p-mr-3 p-ml-3">
        <div className="p-field p-col-12 p-md-3">
            <Button
              label="Tüm Ürünler"
              className={`p-button-raised ${
                label === "" && "p-button-success"
              }`}
              onClick={(e) => {
                setLabel("")
                getAllProductsSearch(
                  searchRequest,
                  basicRows,
                  basicPage
                );
              }}
            />
          </div>
          <div className="p-field p-col-12 p-md-3">
            <Button
              label="Ana Sayfa"
              className={`p-button-raised ${
                label === "Ana Sayfa" && "p-button-success"
              }`}
              onClick={(e) => {
                setLabel("Ana Sayfa");

                getAllProducts(
                  searchRequest,
                  basicRows,
                  basicPage,
                  "Ana Sayfa"
                );
              }}
            />
          </div>

          <div className="p-field p-col-12 p-md-3">
            <Button
              label="Çok Satanlar"
              className={`p-button-raised ${
                label === "Çok Satanlar" && "p-button-success"
              }`}
              onClick={(e) => {
                setLabel("Çok Satanlar");

                getAllProducts(
                  searchRequest,
                  basicRows,
                  basicPage,
                  "Çok Satanlar"
                );
              }}
            />
          </div>
          <div className="p-field p-col-12 p-md-3">
            <Button
              label="Yeniler"
              className={`p-button-raised ${
                label === "Yeniler" && "p-button-success"
              }`}
              onClick={(e) => {
                setLabel("Yeniler");

                getAllProducts(searchRequest, basicRows, basicPage, "Yeniler");
              }}
            />
          </div>
        </div>
      </div>
      <div className="p-fluid p-formgrid p-grid p-mr-3 p-ml-3">
        {Object.keys(searchRequest)
          .filter(
            (item, key) =>
              searchRequest[item] !== undefined &&
              searchRequest[item] !== "" &&
              item !== "labelStatus"
          )
          .map((key, index) => (
            <div className="p-field p-col-2">
              <div className="product-badge filter-item p-text-center p-d-flex p-ai-center p-jc-between">
                {convertParams(key)} -{">"}{" "}
                {key == "stockStatus"
                  ? allStockType?.filter(
                      (item) => item?.key === searchRequest[key]
                    )?.[0]?.value
                  : searchRequest[key]}
                <i
                  onClick={() => {
                    delete searchRequest[key];
                    setChangeFilter(!changeFilter);
                    setAllSubCategoryByCategory([])
                  }}
                  class="pi pi-times remove-filter"
                ></i>
              </div>
            </div>
          ))}
      </div>
      <div className="p-fluid p-formgrid p-grid p-m-2">
        <div className="p-field p-col-12 p-md-12">
          <div className="dataview-demo">
            <div style={{ borderRadius: 15 }}>
              <div className="card">
                {/* <h5>Banner</h5> */}
                <Galleria
                  value={currentBanner}
                  responsiveOptions={responsiveOptions}
                  numVisible={5}
                  circular
                  showItemNavigators
                  showThumbnails={false}
                  showItemNavigatorsOnHover
                  showIndicators
                  item={imageItemTemplate}
                  thumbnail={thumbnailTemplate}
                  autoPlay
                />
              </div>
              <Chip
                label={empty}
                className="p-mb-4 label-chip"
                icon="pi pi-tags"
              />
              <DataView
                value={allProducts}
                layout={layout}
                itemTemplate={itemTemplate}
                lazy
                loading={loading}
                emptyMessage="Ürün Bulunamadı"
              />

              <Paginator
                first={basicFirst}
                rows={basicRows}
                totalRecords={totalRecords}
                rowsPerPageOptions={[12, 24, 36]}
                onPageChange={onBasicPageChange}
              />
            </div>
          </div>
        </div>
      </div>

      <Dialog
        header="Düzenle"
        onHide={(e) => {
          setEditCartShow(false);
        }}
        visible={editCartShow}
      >
        <p>{editProductOrderDetail?.model?.name}</p>
        <InputText
          type="number"
          min={0}
          max={1000000}
          value={editProductOrderDetail?.quantity}
          onChange={(e) => {
            let _editProductOrderDetail = { ...editProductOrderDetail };

            _editProductOrderDetail.quantity = e.target.value;
            setEditProductOrderDetail(_editProductOrderDetail);
          }}
        />

        <Button
          label="Kaydet"
          className="p-button-raised "
          onClick={(e) => {
            services
              .resellerCreateProductOrder({
                resellerProductDetailId: editProductOrderDetail?.id,
                modelId: editProductOrderDetail.model?.id,
                quantity: editProductOrderDetail?.quantity,
              })
              .then((res) => {
                toast?.current?.show({
                  severity: "success",
                  detail: "Ürün güncellenmiştir.",
                });
                getActiveResellerProductOrder();
                setEditCartShow(false);
              })
              .catch((err) => handleError(err, toast));
          }}
        />
      </Dialog>
    </>
  );
};
export default ResellerProductsManagement;
