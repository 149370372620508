import {Dialog} from "primereact/dialog";
import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import React, {useEffect, useRef, useState} from "react";
import {Toast} from "primereact/toast";
import {GiftService} from "./GiftService";
import {handleError} from "../../service/HandleErrorService";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {useHistory} from "react-router-dom";


const LastGiftView = () => {
  const history = useHistory();
  const [totalApproveScore, setTotalApproveScore] = useState(0);
  const [totalRejectScore, setTotalRejectScore] = useState(0);
  const [totalWaitingScore, setTotalWaitingScore] = useState(0);

  const [allGifts, setAllGifts] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    itemsPerPage: 10,
    first: 0,
    totalRecords: 30,
    activePage: 0,
  });
  const [allGiftTransactionType, setAllGiftTransactionType] = useState([]);

  const [lastGiftDialog, setLastGiftDialog] = useState(false);
  const giftService = new GiftService();
  const toast = useRef(null);
  const [searchRequest, setSearchRequest] = useState({});
  const [loading, setLoading] = useState(false);
  const [editImages, setEditImages] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [currentGift, setCurrentGift] = useState({});
  const [viewImageDialog, setViewImageDialog] = useState(false);
  useEffect(() => {

    getAll({activePage: paginationConfig.activePage, itemsPerPage: paginationConfig.itemsPerPage})
  }, [searchRequest]);

  useEffect(() => {
    giftService.getGiftTransactionType().then(res => {

      setAllGiftTransactionType(res)
    }).catch(err => handleError(err, toast))
  }, []);

  const getAll = (pageState) => {
    let _searchRequest = {...searchRequest};

    var sort = "";
    if (_searchRequest.sortField != null && _searchRequest.sortOrder != null) {
      sort += _searchRequest.sortField;
      if (_searchRequest.sortOrder === 1) {
        sort += ",asc";
      } else {
        sort += ",desc";
      }
    } else {
      sort = "createDateTime,asc";
    }
    setLoading(true);
    giftService.getMyGift(_searchRequest, pageState.itemsPerPage, pageState.activePage, sort).then(res => {
      pageState.first = pageState.itemsPerPage * pageState.activePage;
      pageState.totalRecords = res.totalElements;
      setPaginationConfig({
        ...pageState,
      });

      let _totalApproveScore = 0;
      let _totalWaitingScore = 0;
      setAllGifts(res.content);
      res?.content?.forEach(value => {
        if (value.giftTransactionType === 'APPROVED_TO_REQUEST') {
          _totalApproveScore += value?.gift?.giftScore;
        } else if (value?.giftTransactionType === 'WAITING_FOR_RESPONSE') {
          _totalWaitingScore += value?.gift?.giftScore;
        }
      });

      setTotalWaitingScore(_totalWaitingScore)
      setTotalApproveScore(_totalApproveScore);

    }).catch(err => handleError(err, toast))
      .finally(() => setLoading(false));


  }

  const onActivePageChange = (e) => {
    let pageState = {
      ...paginationConfig,
      itemsPerPage: e.rows,
      activePage: e.page,
    };
    getAll(pageState);
  };
  const onSort = (e) => {
    let _lazyParams = {...searchRequest, ...e};
    setSearchRequest(_lazyParams);
  };
  const requesterFilterRender = () => {
    return (
      <InputText placeholder="Ara" value={searchRequest?.requesterName} onChange={e => {
        let _request = {...searchRequest};
        _request.requesterName = e.target.value
        setSearchRequest(_request);
      }} className="p-column-filter"/>

    )
  }

  const currentTypeFilterRender = () => {
    return (
      <Dropdown value={searchRequest?.giftTransactionType}
                options={allGiftTransactionType}
                optionValue="key"
                showClear
                placeholder="Ara"
                optionLabel="value"
                onChange={e => {
                  let _request = {...searchRequest};
                  _request.giftTransactionType = e.target.value
                  setSearchRequest(_request);
                }} className="p-column-filter"/>

    )
  }
  const nameFilterRender = () => {
    return (
      <InputText placeholder="Ara" className="p-column-filter" value={searchRequest?.name} onChange={e => {
        let _request = {...searchRequest};
        _request.name = e.target.value
        setSearchRequest(_request);
      }}/>

    )
  }
  const requesterFilter = requesterFilterRender();
  const currentTypeFilter = currentTypeFilterRender();

  const nameFilter = nameFilterRender();
  const requesterTemplate = (rowData) => {
    return (
      <div>
        {rowData.requester?.name + " " + rowData.requester?.surname}
      </div>
    )
  }
  const giftTransactionTemplate = (rowData) => {
    let _allGiftTransactionType = [...allGiftTransactionType];
    let _response = _allGiftTransactionType.find(gift => gift.key === rowData.giftTransactionType);

    return (
      <div>{_response?.value}</div>

    )
  }

  const imageTemplate = (rowData) => {

    return (
      <img style={{height:"auto",width:'100%'}} src={rowData.publicAddress}/>
    )
  }
  return (
    <>
      <Toast ref={toast}/>


      <div className="datatable-responsive-demo">
        <br/>
        <br/>
        <div style={{paddingBottom: 20}}><Button label="Geri Dön" icon="pi pi-arrow-left"
                                                 className="p-button-outlined p-button-info"
                                                 onClick={e => history.push("giftTransaction")}/></div>
        <div className="p-d-flex p-ai-center export-buttons">
          <p style={{paddingLeft: 10,}}><b>Onaylı Harcanan Toplam Puan:{totalApproveScore}</b></p>

        </div>
        <div className="p-d-flex p-ai-center export-buttons">
          <p style={{paddingLeft: 10}}><b>Onayda Bekleyen Toplam Puan:{totalWaitingScore}</b></p>

      </div>

        <ResponsiveDataTable value={allGifts}
                             loading={loading}
                             paginationConfig={paginationConfig}
                             onActivePageChange={onActivePageChange}
                             emptyMessage="Ürün Bulunamadı."
                             onSort={onSort}
                             sortField={searchRequest.sortField}
                             sortOrder={searchRequest.sortOrder}>

          <Column header="Ürün İsmi" filter filterElement={nameFilter} field="gift.name"/>
          <Column header="Satın Alan kişi " filter filterElement={requesterFilter} field="requester.name"
                  body={e => requesterTemplate(e)}/>
          <Column header="Onay Durumu" filter filterElement={currentTypeFilter} field="giftTransactionType"
                  body={e => giftTransactionTemplate(e)}
          />
          <Column header="Ürün Puanı" field="gift.giftScore" sortable/>
          <Column header="Resimler" body={rowData => {
            return (<div><Button label="Resimler" className="p-button-text p-button"
                                 onClick={e => {
                                   setEditImages(rowData?.gift?.images)
                                   setViewImageDialog(true)
                                 }
                                 }/>
            </div>)
          }}/>


        </ResponsiveDataTable>

      </div>
      <Dialog style={{width: 500}} onHide={e => setViewImageDialog(false)} visible={viewImageDialog}
      >

        <ResponsiveDataTable emptyMessage="Kayıtlı Resim bulunamadı" header="Kayıtlı Resimler" value={editImages}>
          <Column header="Resim" body={imageTemplate} field="publicAddress"/>

        </ResponsiveDataTable>
      </Dialog>

    </>
  )
}
export default LastGiftView
