import React, { useEffect, useRef, useState } from "react";
import { handleError } from "../../service/HandleErrorService";
import { GiftService } from "./GiftService";
import { Button } from "primereact/button";
import { ResponsiveDataTable } from "../../components/ResponsiveDataTable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { useHistory } from "react-router-dom";
import { DataView } from "primereact/dataview";
import { Paginator } from "primereact/paginator";
import { Galleria } from "primereact/galleria";
import "./giftView.css";
import { TriStateCheckbox } from "primereact/tristatecheckbox";

const GiftTransaction = () => {
  const [sortValue, setSortValue] = useState("null");
  const [dropValue, setDropValue] = useState("");
  let _options = ["Büyükten Küçüğe Sırala", "Küçükten Büyüğe Sırala"];
  const [layout, setLayout] = useState("grid");
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(12);
  const [basicPage, setBasicPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(12);

  const [allGifts, setAllGifts] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    itemsPerPage: 12,
    first: 0,
    totalRecords: 36,
    activePage: 0,
  });
  const [allGiftTransactionType, setAllGiftTransactionType] = useState([]);
  const history = useHistory();
  const [lastGiftDialog, setLastGiftDialog] = useState(false);
  const giftService = new GiftService();
  const toast = useRef(null);
  const [searchRequest, setSearchRequest] = useState({});
  const [loading, setLoading] = useState(false);
  const [editImages, setEditImages] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [currentGift, setCurrentGift] = useState({});
  const [approveScore, setApproveScore] = useState(0);
  const loginUser = JSON.parse(localStorage.getItem("user"));
  const [resellerAdmin, setResellerAdmin] = useState(false);
  const [viewImageDialog, setViewImageDialog] = useState(false);
  useEffect(() => {
    getAll({ activePage: basicPage, itemsPerPage: basicRows });
  }, [searchRequest, sortValue]);

  useEffect(() => {
    setResellerAdmin(loginUser?.user?.roles?.includes("RESELLER_ADMIN"));
    giftService
      .getGiftTransactionType()
      .then((res) => {
        setAllGiftTransactionType(res);
      })
      .catch((err) => handleError(err, toast));
  }, []);

  const getAll = (pageState) => {
    let _searchRequest = { ...searchRequest };

    let _sort = "";
    if (sortValue === "asc") {
      _sort = "giftScore,asc";
    } else if (sortValue === "desc") {
      _sort = "giftScore,desc";
    } else {
      _sort = "createDateTime,asc";
    }
    setLoading(true);
    giftService
      .findAllActiveGiftPage(
        _searchRequest,
        pageState.itemsPerPage,
        pageState.activePage,
        _sort
      )
      .then((res) => {
        setBasicFirst(pageState.itemsPerPage * pageState.activePage);
        setBasicRows(pageState.itemsPerPage);
        setBasicPage(pageState.activePage);
        setTotalRecords(res.totalElements);

        setAllGifts(res.content);
      })
      .catch((err) => handleError(err, toast))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getApproveScore();
  }, []);

  const getApproveScore = () => {
    giftService
      .approveResellerScore(loginUser.user?.loginReseller?.id)
      .then((res) => {
        console.log(res);
        setApproveScore(res);
      })
      .catch((err) => handleError(err, toast));
  };
  const accept = () => {
    let _currentGift = currentGift.id;
    giftService
      .buyToGift(_currentGift)
      .then((res) => {
        toast?.current?.show({
          severity: "success",
          summary: "Başarılı İşlem",
          detail: "Ürün Satın İşlemi gerçekleşti.",
          life: 3000,
        });
        getAll({ activePage: basicPage, itemsPerPage: basicRows });
        setConfirmDialog(false);
        getApproveScore();
      })
      .catch((err) => handleError(err, toast));
  };
  const buyTemplate = (rowData) => (
    <Button
      label="Satın Al"
      className="p-button-raised p-button-success"
      disabled={!resellerAdmin}
      onClick={(e) => {
        setCurrentGift(rowData);
        setConfirmDialog(true);
      }}
    />
  );

  const imageTemplate = (rowData) => {
    return (
      <img
        style={{ height: "auto", width: "100%" }}
        src={rowData.publicAddress}
      />
    );
  };

  const requesterTemplate = (rowData) => {
    return (
      <div>{rowData.requester?.name + " " + rowData.requester?.surname}</div>
    );
  };

  const onBasicPageChange = (event) => {
    getAll({ activePage: event.page, itemsPerPage: event.rows });
  };

  const itemTemplateImages = (image) => {
    return <img src={image?.publicAddress} />;
  };
  const renderGridItem = (item) => {
    return (
      <div className="p-col-12 p-md-3">
        <div className="p-grid p-dir-col p-ai-center">
          <Galleria
            value={item?.images}
            autoPlay
            showItemNavigators
            showThumbnails={false}
            showItemNavigatorsOnHover
            showIndicators
            circular
            item={itemTemplateImages}
            numVisible={4}
          ></Galleria>
        </div>
        <div className="row">
          <div className="p-col-6">
            <div style={{ fontSize: 15 }} className="product-name">
              {item?.name}
            </div>

            <div>
              <span className="product-price">
                <b>Ürün Puanı:</b> {item?.giftScore}
              </span>
            </div>
          </div>
          <div className="p-col-6">
            {resellerAdmin ? buyTemplate(item) : ""}
          </div>
        </div>
        <br />
        <br />
      </div>
    );
  };

  const itemTemplates = (product) => {
    if (!product) {
      return;
    }

    return renderGridItem(product);
  };

  return (
    <div>
      <div className="datatable-responsive-demo">
        <div className="card">
          <h4>Kampanya Sona Ermiştir</h4>
        </div>
      </div>
     {/*<Toast ref={toast} />
      <div className="datatable-responsive-demo">
        <div>
          <span className="p-float-label marginInput">
            {resellerAdmin ? (
              <p style={{ paddingLeft: 10 }}>
                <b>Kullanılabilir Puanınız :{approveScore}</b>
              </p>
            ) : (
              ""
            )}
            <InputText
              placeholder="Ürün Ara..."
              value={searchRequest?.name}
              onChange={(e) => {
                let _searchRequest = { ...searchRequest };
                _searchRequest.name = e.target.value;
                setSearchRequest(_searchRequest);
              }}
            />
          </span>

          <span className="p-float-label marginInput">
            <Dropdown
              value={dropValue}
              options={_options}
              showClear
              placeholder="Puana Göre Sırala"
              onChange={(e) => {
                console.log(e);
                if (e.target.value === "Büyükten Küçüğe Sırala") {
                  setSortValue("desc");
                  setDropValue(e.target.value);
                } else if (e.target.value === "Küçükten Büyüğe Sırala") {
                  setSortValue("asc");
                  setDropValue(e.target.value);
                } else {
                  setSortValue("");
                  setDropValue("");
                }
              }}
            />
          </span>

          <div className="p-d-flex p-justify-between">
            <div />
            {resellerAdmin ? (
              <Button
                type="button"
                icon="pi pi-external-link"
                label="Geçmiş Hediyelerim"
                className="p-button p-button-sm"
                onClick={(e) => history.push("/lastResellerScoreOfGift")}
              />
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="card">
          <DataView
            value={allGifts}
            layout={layout}
            itemTemplate={itemTemplates}
            lazy
            loading={loading}
            emptyMessage="Ürün Bulunamadı"
          />
        </div>
        <Paginator
          first={basicFirst}
          rows={basicRows}
          totalRecords={totalRecords}
          rowsPerPageOptions={[12, 24, 36]}
          onPageChange={onBasicPageChange}
        />
      </div>

      <ConfirmDialog
        visible={confirmDialog}
        onHide={() => setConfirmDialog(false)}
        message="Satın Alma İşlemini Onaylıyor musunuz?"
        header={<b style={{ color: "green" }}>Satın Alma İşlemi</b>}
        icon="pi
pi-thumbs-up"
        acceptLabel="Evet"
        rejectLabel="Vazgeç"
        accept={accept}
      />

      <Dialog
        style={{ width: "100%" }}
        onHide={(e) => setViewImageDialog(false)}
        visible={viewImageDialog}
      >
        <ResponsiveDataTable
          emptyMessage="Kayıtlı Resim bulunamadı"
          header="Kayıtlı Resimler"
          value={editImages}
        >
          <Column header="Resim" body={imageTemplate} field="publicAddress" />
        </ResponsiveDataTable>
      </Dialog>*/}
    </div>
  );
};
export default GiftTransaction;
