import React, {useEffect, useRef, useState} from "react";
import {Toast} from 'primereact/toast';
import ReportingService from "./httpService";
import ReactSpeedometer from "react-d3-speedometer"

const Dashboard = () => {

  const toast = useRef(null);
  const reportingService = new ReportingService();

  const ttgAdmin = JSON.parse(localStorage.getItem('user')).user?.roles.includes("TTG_ADMIN");

  const [saleQuantityTotal, setSaleQuantityTotal] = useState(0);
  const [saleQuantityTotalText, setSaleQuantityTotalText] = useState(null);
  const [saleQuantityAverage, setSaleQuantityAverage] = useState(null);
  const [saleQuantitySegments, setSaleQuantitySegments] = useState([]);

  const [salePriceTotal, setSalePriceTotal] = useState(0);
  const [salePriceTotalText, setSalePriceTotalText] = useState(null);
  const [salePriceAverage, setSalePriceAverage] = useState(null);
  const [salePriceSegments, setSalePriceSegments] = useState([]);

  useEffect(() => {
    reportingService.getCalculationsValues().then(res => {
      console.log("getCalculationsValues", res);

      setSaleQuantityTotalText(res.saleQuantityTotal);
      if (res.saleQuantityTotal <= res.saleQuantityAverage) {
        setSaleQuantityTotal(res.saleQuantityTotal);
      } else {
        setSaleQuantityTotal(res.saleQuantityAverage);
      }

      let saleQuantityRatio = res.saleQuantityAverage / 5;
      let quantitySegmentValue = 0;
      for (let i = 0; i <= 5; i++) {
        saleQuantitySegments.push(quantitySegmentValue);
        quantitySegmentValue += saleQuantityRatio
      }
      setSaleQuantityAverage(res.saleQuantityAverage);

      setSalePriceTotalText(res.salePriceTotal);
      if (res.salePriceTotal <= res.salePriceAverage) {
        setSalePriceTotal(res.salePriceTotal);
      } else {
        setSalePriceTotal(res.salePriceAverage);
      }

      let salePriceRatio = res.salePriceAverage / 5;
      let priceSegmentValue = 0;
      for (let i = 0; i <= 5; i++) {
        console.log(priceSegmentValue);
        salePriceSegments.push(priceSegmentValue);
        priceSegmentValue += salePriceRatio
      }
      setSalePriceAverage(res.salePriceAverage);

    })
  }, []);

  const addCommas = num => num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const addCommasWithToFixed = num => num?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return (
    <>
      <Toast ref={toast}/>
      <div className="datatable-responsive-demo">
        <div className="card p-grid">
          {ttgAdmin &&
          <>
            <div className="p-col-12 p-md-6" style={{textAlign: 'center', padding: 0}}>
              <h4>Günlük Toplam Aksesuar Satış Adedi</h4>
              {saleQuantityAverage &&
              <ReactSpeedometer
                maxValue={saleQuantityAverage}
                value={saleQuantityTotal}
                currentValueText={addCommas(saleQuantityTotalText)}
                needleColor="red"
                customSegmentStops={saleQuantitySegments}
                startColor="#B0C4DE"
                endColor="#228B22"
              />}
            </div>
            <div className="p-col-12 p-md-6" style={{textAlign: 'center'}}>
              <h4>Günlük Toplam Satış Tutarı</h4>
              {salePriceAverage &&
              <ReactSpeedometer
                maxValue={salePriceAverage}
                value={salePriceTotal}
                currentValueText={addCommasWithToFixed(salePriceTotalText)}
                needleColor="red"
                customSegmentStops={salePriceSegments}
                startColor="#B0C4DE"
                endColor="#228B22"
              />}
            </div>
          </>}
        </div>
      </div>
    </>
  )

};

export default Dashboard;
