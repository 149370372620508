import AspAxios from "../../service/AspAxios";
import {
  activeGift,
  approveGift,
  approveResellerScore,
  buyToGift,
  findAllActiveGifts,
  findAllGifts,
  findAllTransactionGifts,
  findAllTransactionGiftsEager,
  getGiftTransactionType,
  getMyGift,
  passiveGift,
  upsertGift,
  upsertGiftImages,
  allData,
  getAllDisty
} from "../../service/config";

export class GiftService {
  findAllGiftPage(request, _size, _page, _sort) {
    return new Promise((resolve, reject) => {
      AspAxios.post(findAllGifts + "?size=" + _size + "&page=" + _page + _sort, request)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }
  findAllActiveGiftPage(request, _size, _page, _sort) {
    return new Promise((resolve, reject) => {
      AspAxios.post(findAllActiveGifts, request, {
        params: {
          size: _size,
          page: _page,
          sort: _sort,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  findAllTransactionGiftPage(request, _size, _page, _sort) {
    return new Promise((resolve, reject) => {
      AspAxios.post(findAllTransactionGifts, request, {
        params: {
          size: _size,
          page: _page,
          sort: _sort,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  findAllTransactionGiftPageEager(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(findAllTransactionGiftsEager, request)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  getGiftTransactionType() {
    return new Promise((resolve, reject) => {
      AspAxios.get(getGiftTransactionType)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }
  buyToGift(id) {
    return new Promise((resolve, reject) => {
      AspAxios.get(buyToGift + "/" + id)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  uploadGiftImages(files, id) {
    return new Promise((resolve, reject) => {
      AspAxios.post(upsertGiftImages + "/" + id, files)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  upserGift(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(upsertGift, request)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  activeGift(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(activeGift, request)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  passiveGift(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(passiveGift, request)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  approveGiftTransaction(value, id) {
    return new Promise((resolve, reject) => {
      AspAxios.get(approveGift + "/" + value + "/" + id)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  approveResellerScore(id) {
    return new Promise((resolve, reject) => {
      AspAxios.get(approveResellerScore + "/" + id)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }
  getMyGift(request, _size, _page, _sort) {
    return new Promise((resolve, reject) => {
      AspAxios.post(getMyGift, request, {
        params: {
          size: _size,
          page: _page,
          sort: _sort,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  getAllData(request) {
    return new Promise((resolve, reject) => {
      AspAxios.get(allData, request)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  getAllDisty(request) {
    return new Promise((resolve, reject) => {
      AspAxios.get(getAllDisty, request)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }
}
