import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import React, {useEffect, useRef, useState} from "react";
import {InputText} from "primereact/inputtext";
import {handleError} from "../../service/HandleErrorService";
import {GiftService} from "./GiftService";
import {ConfirmDialog} from "primereact/confirmdialog";
import {Dialog} from "primereact/dialog";
import {Dropdown} from "primereact/dropdown";
import {giftTransactionType} from "../../service/config";
import moment from "moment/moment";
import DistyDropdown from "../../components/DistyDropdown";

const GiftApprove = () => {

  const [allGifts, setAllGifts] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    itemsPerPage: 10,
    first: 0,
    totalRecords: 30,
    activePage: 0,
  });
  const giftService = new GiftService();


  const toast = useRef(null);
  const [searchRequest, setSearchRequest] = useState({});
  const [loading, setLoading] = useState(false);
  const [editImages, setEditImages] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [currentGift, setCurrentGift] = useState({});
  const [viewImageDialog, setViewImageDialog] = useState(false);
  const [exportExcelDisabled, setExportExcelDisabled] = useState(false);
  const [allGiftTransactionType, setAllGiftTransactionType] = useState([]);
  const [allDisty, setAllDisty] = useState([]);
  useEffect(() => {

    getAll({activePage: paginationConfig.activePage, itemsPerPage: paginationConfig.itemsPerPage})
  }, [searchRequest]);

  useEffect(()=>{
    giftService.getGiftTransactionType().then(res => {

      setAllGiftTransactionType(res)
    }).catch(err => handleError(err, toast))

    giftService.getAllDisty().then(res => {

      setAllDisty(res)
    }).catch(err => handleError(err, toast))
  },[]);


  const getAll = (pageState) => {
    let _searchRequest = {...searchRequest};

    var sort = "";
    if (_searchRequest.sortField != null && _searchRequest.sortOrder != null) {
      sort += _searchRequest.sortField;
      if (_searchRequest.sortOrder === 1) {
        sort += ",asc";
      } else {
        sort += ",desc";
      }
    } else {
      sort = "createDateTime,desc";
    }
    setLoading(true);
    giftService.findAllTransactionGiftPage(_searchRequest, pageState.itemsPerPage, pageState.activePage, sort).then(res => {
      pageState.first = pageState.itemsPerPage * pageState.activePage;
      pageState.totalRecords = res.totalElements;
      setPaginationConfig({
        ...pageState,
      });
      
      res?.content?.forEach(r => {
        r.resellerScore = r?.reseller?.resellerScore?.unapprovedScore + "/" +  r?.reseller?.resellerScore?.approvedScore;
      });

      setAllGifts(res.content);

    }).catch(err => handleError(err, toast))
      .finally(() => setLoading(false));


  }

  const onActivePageChange = (e) => {
    let pageState = {
      ...paginationConfig,
      itemsPerPage: e.rows,
      activePage: e.page,
    };
    getAll(pageState);
  };
  const onSort = (e) => {
    let _lazyParams = {...searchRequest, ...e};
    setSearchRequest(_lazyParams);
  };

  const reselllerFilterRender = () => {
    return (
      <InputText placeholder="Ara" value={searchRequest?.resellerCode} onChange={e => {
        let _request = {...searchRequest};
        _request.resellerCode = e.target.value
        setSearchRequest(_request);
      }} className="p-column-filter"/>

    )
  }
  const requesterFilterRender = () => {
    return (
      <InputText placeholder="Ara" value={searchRequest?.requesterName} onChange={e => {
        let _request = {...searchRequest};
        _request.requesterName = e.target.value
        setSearchRequest(_request);
      }} className="p-column-filter"/>

    )
  }

  const currentTypeFilterRender = () => {
    return (
      <Dropdown value={searchRequest?.giftTransactionType}
                options={allGiftTransactionType}
                optionValue="key"
                showClear
                placeholder="Ara"
                optionLabel="value"
                onChange={e => {
                  let _request = {...searchRequest};
                  _request.giftTransactionType = e.target.value
                  setSearchRequest(_request);
                }} className="p-column-filter"/>

    )
  }

  const distyFilterRender = () => {
    return (
      <Dropdown value={searchRequest?.distyName}
                options={allDisty}
                optionValue="name"
                showClear
                placeholder="Ara"
                optionLabel="name"
                onChange={e => {
                  let _request = {...searchRequest};
                  _request.distyName = e.target.value
                  setSearchRequest(_request);
                }} className="p-column-filter"/>

    )
  }
  console.log(allDisty)
  const nameFilterRender = () => {
    return (
      <InputText placeholder="Ara" className="p-column-filter" value={searchRequest?.name} onChange={e => {
        let _request = {...searchRequest};
        _request.name = e.target.value
        setSearchRequest(_request);
      }}/>

    )
  }
  const accept = (e, value) => {


    giftService.approveGiftTransaction(value, currentGift.id).then(res => {
      toast?.current?.show({
        severity: 'success',
        summary: "11",
        detail: 'Başarılı İşlem.',
        life: 3000,

      });
      getAll({activePage: paginationConfig.activePage, itemsPerPage: paginationConfig.itemsPerPage})
      setConfirmDialog(false);

    }).catch(err => handleError(err, toast))
  }
  const buyTemplate = (rowData) =>
    <Button disabled={rowData.giftTransactionType==='APPROVED_TO_REQUEST'||
    rowData.giftTransactionType==='REJECTED_TO_REQUEST'}
            label="Onay İşlemi" className="p-button-raised p-button-success"
            onClick={e => {
              setCurrentGift(rowData);
              setConfirmDialog(true);
            }
            }/>

  const requesterFilter = requesterFilterRender();
  const currentTypeFilter = currentTypeFilterRender();
  const distyFilter = distyFilterRender();

  const reselllerFilter = reselllerFilterRender();
  const nameFilter = nameFilterRender();

  const imageTemplate = (rowData) => {

    return (
      <img style={{height:"auto",width:'100%'}}  src={rowData.publicAddress}/>
    )
  }
  const resellerTemplate = (rowData) => {

    return (
      <div>
        {rowData.reseller?.code}<br/> {rowData.reseller?.name}
      </div>
    )
  }

  const requesterTemplate = (rowData) => {
    return (
      <div>
        {rowData.requester?.name + " " + rowData.requester?.surname}
      </div>
    )
  }

  const dateTemplate = (rowData) => {
    return (
      <div>
        {moment(rowData?.requestDate).format("DD/MM/YYYY")}
      </div>
    )
  }
  const giftTransactionTemplate = (rowData) => {
    let _allGiftTransactionType = [...allGiftTransactionType];
    let _response = _allGiftTransactionType.find(gift => gift.key === rowData.giftTransactionType);

    return (
      <div>{_response?.value}</div>

    )
  }

  const exportExcel = () => {
    setExportExcelDisabled(true);
    let _searchRequest = {...searchRequest};

    giftService.findAllTransactionGiftPageEager(_searchRequest).then(res => {
      console.log("findAllTransactionGiftPageEager", res);
      import('xlsx').then(xlsx => {
        var excelList = [];
        res.forEach(a => {
          let _allGiftTransactionType = [...allGiftTransactionType];
          let _response = _allGiftTransactionType.find(gift => gift.key === a.giftTransactionType);

          excelList.push({
            "Ürün İsmi": a.gift?.name,
            "Bayi Kod": a.reseller?.code,
            "Bayi Ad": a.reseller?.name,
            "Distribütör Kodu": a.distyCode,
            "Satın Alan kişi": a.requester?.name + " " + a.requester?.surname,
            "Onay Durumu": _response?.value,
            "Ürün Puanı": a.gift?.giftScore,
            "Bayi Onay Bekleyen Puan": a.reseller?.resellerScore?.unapprovedScore,
            "Bayi Kullanılabilir Puanı": a.reseller?.resellerScore?.approvedScore,
            "İstek Tarihi": moment(a?.requestDate).format("DD/MM/YYYY"),
          })
        });
        const worksheet = xlsx.utils.json_to_sheet(excelList);
        var wscols = [
          {width: 18},
          {width: 18},
          {width: 18},
          {width: 18},
          {width: 18},
          {width: 18},
          {width: 18},
          {width: 18},
          {width: 18}
        ];
        worksheet["!cols"] = wscols;
        const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
        const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
        saveAsExcelFile(excelBuffer, 'Bayi Puan Hediyeleri Listesi');
      });
    }).catch(err => {
      toast?.current?.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'İşlem bilgisi getirme başarısız.'
      });
      console.log(err);
    }).finally(() => {
      setExportExcelDisabled(false);
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  };

  return (
    <>
      <div className="datatable-responsive-demo">
        <Button style={{marginLeft: 10}} label="Bayi Puan Hediyeleri Listesini İndir" type="button" icon="pi pi-file-excel" disabled={exportExcelDisabled}
                onClick={exportExcel} className="p-button-success p-mr-2" data-pr-tooltip="XLS"/>
        <ResponsiveDataTable value={allGifts}
                             style={{fontSize: '12px'}}
                             loading={loading}
                             paginationConfig={paginationConfig}
                             onActivePageChange={onActivePageChange}
                             emptyMessage="Ürün Bulunamadı."
                             onSort={onSort}
                             sortField={searchRequest.sortField}
                             sortOrder={searchRequest.sortOrder}>

          <Column header="Ürün İsmi" filter filterElement={nameFilter} field="gift.name"/>
          <Column header="Bayi " filter filterElement={reselllerFilter} field="reseller.code"
                  body={e => resellerTemplate(e)}/>
          <Column header="Satın Alan kişi " filter filterElement={requesterFilter} field="requester.name"
                  body={e => requesterTemplate(e)}/>
          <Column header="Onay Durumu" filter filterElement={currentTypeFilter} field="giftTransactionType"
                  body={e => giftTransactionTemplate(e)}
          />
          <Column header="Ürün Puanı" field="gift.giftScore" sortable/>
          <Column header="Bayi Onay Bekleyen Puan/ Bayi Kullanılabilir Puanı" field="resellerScore" headerStyle={{width: "14rem"}}/>
          <Column header="İstek Tarihi" field="requestDate"
                  body={e => dateTemplate(e)}/>
          <Column header="Resimler" body={rowData => {
            return (<div><Button label="Resimler" className="p-button-text p-button"
                                 onClick={e => {
                                   setEditImages(rowData?.gift?.images)
                                   setViewImageDialog(true)
                                 }
                                 }/>
            </div>)
          }}/>
          <Column header="Distribütör" filter filterElement={distyFilter} field="reseller.disty.name"
          />
          <Column body={buyTemplate}/>


        </ResponsiveDataTable>
      </div>

      <ConfirmDialog visible={confirmDialog} onHide={() => setConfirmDialog(false)}
                     message={`"${currentGift.gift?.name}" adlı  ürünü '${currentGift?.reseller?.code}' kodlu bayi satın alma talebinde bulundu.
                     Onaylıyor musunuz?`} icon="pi
pi-thumbs-up"
                     acceptLabel="Onayla" rejectLabel="Reddet" rejectClassName="p-button-danger"
                     reject={e => accept(e, "false")}
                     accept={e => accept(e, "true")}/>

      <Dialog style={{width: 500}} onHide={e => setViewImageDialog(false)} visible={viewImageDialog}
      >

        <ResponsiveDataTable emptyMessage="Kayıtlı Resim bulunamadı" header="Kayıtlı Resimler" value={editImages}>
          <Column header="Resim" body={imageTemplate} field="publicAddress"/>

        </ResponsiveDataTable>
      </Dialog>


    </>
  )

}
export default GiftApprove;
