import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import ProductService from "../product/httpService";
import React, {useEffect, useRef, useState} from "react";
import {handleError} from "../../service/HandleErrorService";
import {Toast} from "primereact/toast";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {useHistory} from "react-router-dom";
import CanSellService from "../canSell/httpService";
import {ConfirmDialog} from "primereact/confirmdialog";
import {InputTextarea} from "primereact/inputtextarea";
import {Dialog} from "primereact/dialog";
import {Galleria} from "primereact/galleria";
import {Checkbox} from "primereact/checkbox";
import moment from "moment";

export const WaitingMyAction = () => {
  const [allCategory, setAllCategory] = useState([]);
  const [allSubCategory, setAllSubCategory] = useState([]);
  const [currentModel, setCurrentModel] = useState();
  const [updatedModel, setUpdatedModel] = useState();
  const [dialog, setDialog] = useState(false);
  let productService = new ProductService();
  const history = useHistory();
  const dt = useRef(null);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2,setLoading2]=useState(false);

  const [allProduct, setAllProduct] = useState([]);
  const [approveDescription, setApproveDescription] = useState();
  const [searchRequest, setSearchRequest] = useState({});
  const canSellService = new CanSellService();
  const [rejectListDialog, setRejectListDialog] = useState(false);
  const [value, setValue] = useState(false);
  const [allBrand, setAllBrand] = useState([]);
  const [allSupply, setAllSupply] = useState([]);
  const [excelList, setExcelList] = useState([]);
  const [allApproveTypes, setAllApproveTypes] = useState([]);
  const [pictureDialog, setPictureDialog] = useState(false);
  const [pictureList, setPictureList] = useState([]);
  const [deletePictureList, setDeletePictureList] = useState([]);
  const [batchApprove, setBatchApprove] = useState(true);
  const [selectApproveDescription, setSelectApproveDescription] = useState();
  const [selectApprove, setSelectApprove] = useState();
  const [approveList, setApproveList] = useState([]);
  const [selectedProducts8, setSelectedProducts8] = useState([]);
  const [selectDialog, setSelectDialog] = useState(false);
  const [controls, setControls] = useState({
    categorySame: true,
    subCategorySame: true,
    brandSame: true,
    nameSame: true,
    barcodeName: true,
    supplyStatusSame: true,
    originSame: true,


  });

  const sortData = (data) => {
    const x = data?.sort(function(a, b){
    if(a.name < b.name) { return -1; }
    if(a.name > b.name) { return 1; }
    return 0;
})
return x;
}

  const responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];

  const itemTemplate = (item) => {
    return <img src={item?.baseUrl} style={{width: 200, height: 200}}/>;
  }

  const thumbnailTemplate = (item) => {
    return <img src={item?.baseUrl}/>;
  }
  const [paginationConfig, setPaginationConfig] = useState({
    itemsPerPage: 10,
    first: 0,
    totalRecords: 30,
    activePage: 0,
  });
  const toast = useRef();

  useEffect(() => {
    canSellService.getAllCategory().then(res => {
      setAllCategory(sortData(res))
    })
    canSellService.getAllSubCategory().then(res => {
      setAllSubCategory(sortData(res))
    })
    canSellService.getAllBrand().then(setAllBrand);
    canSellService.getAllSupply().then(setAllSupply);
    getAll({...paginationConfig, activePage: 0});
    productService.getapproveTypes().then(res => {
      setAllApproveTypes(res)
    })
  }, []);

  const getAll = (pageState) => {






        productService.getWaitingProductsList(searchRequest).then(res2 => {
          setSelectedProducts8([]);
          let _excelList = [];
          res2?.forEach(value => {
            let _excelModel = {};
            _excelModel['Kategori'] = value?.subCategory.category.name;
            _excelModel['Alt Kategori'] = value?.subCategory.name;
            _excelModel['Marka'] = value?.brand.name;
            _excelModel['Tedarikçi'] = value?.supplyName;
            _excelModel['Ürün Adı'] = value?.name;
            _excelModel['İstek Tarihi'] = moment(value?.requestDate).format("DD-MM-yyyy hh:mm:ss")
            _excelModel['Barkod'] = value?.barcode;
            _excelModel['Ürün Açıklaması'] = value?.description;
            _excelModel['Tedarik Durumu'] = value?.supplyStatus;
            _excelModel['Menşei'] = value?.origin;
            _excelModel['Geçerli Olacağı Tarih'] = moment(value?.startDate).format("DD-MM-yyyy")
            _excelModel['Distribütör Alış Fiyatı'] = value?.distyPrice;
            _excelModel['Önerilen EDM Alış Fiyatı'] = value?.recommendedEdmPrice;
            _excelModel['Önerilen Bayi Alış Fiyatı'] = value?.recommendedResellerPrice;
            _excelModel['Önerilen Satış Fiyatı'] = value?.recommendedSellPrice;


            if (value.eol) {
              _excelModel['Eol'] = "EVET";
            } else if (value.eol === undefined) {
              _excelModel['Eol'] = "";
            } else {
              _excelModel['Eol'] = "HAYIR";

            }

            _excelList.push(_excelModel);
          })
          setExcelList(_excelList)
          setApproveList(res2)
        }).catch(err => handleError(err, toast))


  }
  const supplyTemplate = (e) => {
    let _supply = allSupply.find(value => e.supplyName === value.name);

    return _supply?.disties !== undefined ? _supply?.disties[0]?.name : _supply?.name;

  }

  const exportExcel = () => {
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(excelList);
      const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
      const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
      saveAsExcelFile(excelBuffer, 'products');
    });
  }

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, "Ürün Listesi" + EXCEL_EXTENSION);
    });
  }
  const onActivePageChange = (e) => {
    let pageState = {
      ...paginationConfig,
      itemsPerPage: e.rows,
      activePage: e.page,
    };
    getAll(pageState);
  };

  const eolTemplate = (e) => {
    if (e.eol) {
      return <div>Evet</div>
    } else if (e.eol === undefined) {
      return <div>Girilmedi</div>
    } else
      return <div>Hayır</div>


  }
  const actions = (rowData) => {
    return <div className="p-justify-center"><Button

      className="p-button-rounded p-button-success p-mr-2"
      label="Onayla"
      disabled={loading}
      loading={loading}
      onClick={e => approveModel(e, rowData)}


    />
      <Button

        className="p-button-rounded p-button-danger p-mr-2"
        label="Reddet"
        onClick={e => {
          setSelectApprove(rowData);
          setSelectDialog(true)
        }}


      />
    </div>

  }

  const approveModel = (e, rowData) => {
    setLoading(true);
    productService.approveModel(rowData, true).then(res => {
      toast.current?.show({
        severity: "success",
        detail: "Ürün Onaylandı",
        life: 3000,
      });
      setLoading(false);
      getAll({activePage: paginationConfig.activePage, itemsPerPage: paginationConfig.itemsPerPage});
    }).catch(err=>{
      setLoading(false);
      handleError(err,toast)
    });


  }
  const rejectModel = () => {

    setLoading(true);
    selectApprove.approveDescription = selectApproveDescription;
    productService.approveModel(selectApprove, false).then(res => {
      toast.current?.show({
        severity: "info",
        detail: "Ürün Reddedildi",
        life: 3000,
      });
      setSelectDialog(false)
      setLoading(false);
      getAll({activePage: paginationConfig.activePage, itemsPerPage: paginationConfig.itemsPerPage});
    }).catch(err=>{
      setLoading(false);
      handleError(err,toast)
    });


  }

  const rejectSubmit = () => {

    let _selectedProducts8 = selectedProducts8.map(value => {
      return {
        id: value.id,
        currentModelId: value.currentModelId,
        approveDescription: approveDescription
      }
    })
    productService.approveModelList(_selectedProducts8, value).then(res => {
      toast.current?.show({
        severity: "success",
        detail: "Toplu Reddetme başarılı",
        life: 3000,
      });
      getAll({activePage: paginationConfig.activePage, itemsPerPage: paginationConfig.itemsPerPage});
    })

    setRejectListDialog(false)
  }

  const onSelectProduct = (e) => {
    if (e.value?.length === 25) {
      setSelectedProducts8(approveList)

    } else if (e.value?.length === 0) {
      setSelectedProducts8([])


    } else {
      setSelectedProducts8(e.value)

    }

  }


  const currentModelTemplate = (rowData) => {


    return <Button
      className="p-button-info p-button-text"
      label="Detay"
      onClick={e => {
        productService.getById(rowData.currentModelId).then(res => {
          let _controls = {};
          rowData?.subCategory?.category?.name !== res?.subCategory?.category?.name ? _controls.categorySame = false : _controls.categorySame = true;


          rowData?.subCategory?.name !== res?.subCategory?.name ? _controls.subCategorySame = false : _controls.subCategorySame = true;


          rowData?.brand?.name !== res?.brand?.name ? _controls.brandSame = false : _controls.brandSame = true;


          rowData?.name !== res?.name ? _controls.name = false : _controls.name = true;
          rowData?.description !== res?.description ? _controls.description = false : _controls.description = true;


          rowData?.barcode !== res?.barcode ? _controls.barcodeName = false : _controls.barcodeName = true;

          rowData?.origin !== res?.origin ? _controls.originSame = false : _controls.originSame = true;


          rowData?.supplyStatus !== res?.supplyStatus ? _controls.supplyStatusSame = false : _controls.supplyStatusSame = true;
          setCurrentModel(res)

          setControls(_controls)


          setDialog(true)


        }).catch(err => handleError(err, toast))
        setPictureList(rowData?.pictures?.filter(value => value?.pictureType === "WEB" && value?.pictureCrudType === "WANTINSERT"))
        setDeletePictureList(rowData?.pictures?.filter(value => value?.pictureType === "WEB" && value?.pictureCrudType === "WANTDELETE"));
        console.log(deletePictureList)
        setUpdatedModel(rowData)


      }
      }/>
  }

  const pictureTemplate = (rowData) => {

    return <Button
      className="p-button-info p-button-text"
      label="Resimler"
      onClick={e => {
        setPictureList(rowData?.pictures?.filter(value => value?.pictureType === "WEB"))
        setPictureDialog(true)
      }
      }/>

  }
  const modelTemplate = (e) => {

    return <div><InputTextarea style={{fontSize: 10, backgroundColor: 'lawngreen', width: '100%', margin: 0, padding: 0}} autoResize disabled
                               value={e?.name}/>
    </div>
  }

  const dateTemplate = (e) => {
    if (e?.requestDate) {
      return <div>
        {moment(e?.requestDate).format("DD/MM/YYYY")}
      </div>
    } else {
      return <div/>
    }

  }


  return (
    <>
      <Toast ref={toast}/>

      <div className="card">
        <div className="p-formgroup-inline">
        <div className="p-field">
          <label htmlFor="category" className="p-sr-only">Marka</label>
          <Dropdown id="category" value={searchRequest.category} options={allCategory} showClear placeholder="Kategori"
                    optionLabel="name" filter optionValue="name"
                    onChange={e => {
                      let _searchRequest = {...searchRequest};
                      _searchRequest.category = e.target.value;
                      setSearchRequest(_searchRequest);
                    }

                    }/>
        </div>
          <div className="p-field">
          <label htmlFor="subCategory" className="p-sr-only">Marka</label>
          <Dropdown id="subCategory" value={searchRequest.subCategory} options={allSubCategory} showClear placeholder="Alt Kategori"
                    optionLabel="name" filter optionValue="name"
                    onChange={e => {
                      let _searchRequest = {...searchRequest};
                      _searchRequest.subCategory = e.target.value;
                      setSearchRequest(_searchRequest);
                    }

                    }/>
        </div>


          <div className="p-field">
            <label htmlFor="brand" className="p-sr-only">Marka</label>
            <Dropdown id="brand" value={searchRequest.brand} options={allBrand} showClear placeholder="Marka"
                      optionLabel="name" optionValue="name"
                      onChange={e => {
                        let _searchRequest = {...searchRequest};
                        _searchRequest.brand = e.target.value;
                        setSearchRequest(_searchRequest);
                      }

                      }/>
          </div>

          <div className="p-field">
            <label htmlFor="supply" className="p-sr-only">Tedarikçi</label>
            <Dropdown id="supply" value={searchRequest.supply} options={allSupply} optionValue="name"
                      optionLabel={e => {

                        if (e?.disties !== undefined) {

                          return e?.disties[0]?.name;
                        } else {
                          return e.name
                        }
                      }}
                      placeholder="Tedarikçi"
                      showClear
                      onChange={e => {
                        let _searchRequest = {...searchRequest};
                        _searchRequest.supply = e.target.value;
                        setSearchRequest(_searchRequest);
                      }

                      }/>
          </div>

          <div className="p-field">
            <label htmlFor="name" className="p-sr-only">Ürün</label>
            <InputText id="name" value={searchRequest.modelName} type="text" placeholder="Ürün"
                       onChange={e => {
                         let _searchRequest = {...searchRequest};
                         _searchRequest.modelName = e.target.value;
                         setSearchRequest(_searchRequest);
                       }

                       }/>
          </div>

          <div className="p-field">
            <label htmlFor="barcode" className="p-sr-only">Barkod</label>
            <InputText id="barcode" value={searchRequest.barcode} type="text" placeholder="Barkod"
                       onChange={e => {
                         let _searchRequest = {...searchRequest};
                         _searchRequest.barcode = e.target.value;
                         setSearchRequest(_searchRequest);
                       }

                       }/>
          </div>

          <div className="p-field">
            <label htmlFor="supply" className="p-sr-only">Tedarikçi Durumu</label>
            <Dropdown id="supply" value={searchRequest.supplyStatus} options={["AÇIK", "KAPALI"]}
                      placeholder="Tedarikçi Durumu"
                      showClear
                      onChange={e => {
                        let _searchRequest = {...searchRequest};
                        _searchRequest.supplyStatus = e.target.value;
                        setSearchRequest(_searchRequest);
                      }

                      }/>
          </div>

          <div className="p-field">
            <label htmlFor="origin" className="p-sr-only">Menşei</label>
            <InputText id="origin" value={searchRequest.origin} type="text" placeholder="Menşei"
                       onChange={e => {
                         let _searchRequest = {...searchRequest};
                         _searchRequest.origin = e.target.value;
                         setSearchRequest(_searchRequest);
                       }

                       }/>
          </div>

          <div className="p-field">
            <label htmlFor="supply" className="p-sr-only">EOL</label>
            <Dropdown id="supply" value={searchRequest.eolValue}
                      options={["EVET", "HAYIR"]}
                      placeholder="EOL"
                      showClear
                      onChange={e => {
                        let _searchRequest = {...searchRequest};
                        if (e.target.value === "EVET") {
                          _searchRequest.eol = true;
                        } else if (e.target.value === "HAYIR") {
                          _searchRequest.eol = false;
                        } else {
                          _searchRequest.eol = undefined;

                        }
                        _searchRequest.eolValue = e.target.value;

                        setSearchRequest(_searchRequest);

                      }

                      }/>
          </div>


          <Button icon="pi pi-search" type="button" label="Ara"
                  onClick={e => getAll({
                    activePage: paginationConfig.activePage,
                    itemsPerPage: paginationConfig.itemsPerPage
                  })
                  }/>


        </div>
      </div>

      <Button style={{marginBottom: 5}} label="Ürün Listesini İndir" type="button" icon="pi pi-file-excel"
              onClick={exportExcel} className="p-button-success p-mr-2" data-pr-tooltip="XLS"/>

      <Button disabled={!selectedProducts8?.length > 0 || loading} style={{marginBottom: 5, alignContent: 'center'}}
              label="Seçilenleri Onayla"
              onClick={e => {

                setLoading(true);
                let checkList = selectedProducts8.map(value => {
                  return {
                    id: value.id,
                    currentModelId: value.currentModelId,
                  }
                });
                productService.approveModelList(checkList, true).then(res => {
                  toast.current?.show({
                    severity: "success",
                    detail: "Toplu Onaylama başarılı",
                    life: 3000,
                  });
                  setLoading(false);

                  getAll({activePage: paginationConfig.activePage, itemsPerPage: paginationConfig.itemsPerPage});

                }).catch(err=>{
                  setLoading(false);
                  handleError(err,toast)
                });

              }}
              className="p-button-successbutton"/>
      <Button disabled={!selectedProducts8?.length > 0 || loading} style={{marginBottom: 5}} onClick={e => {
        setValue(false);
        setRejectListDialog(true);
      }} label="Seçilenleri Reddet" className="p-button-danger"/>

      <div className="datatable-responsive-demo">
        <ResponsiveDataTable value={approveList}
                             loading={loading}
                             style={{fontSize: 11}}
                             ref={dt}
                             selectionMode="checkbox" selection={selectedProducts8}
                             onSelectionChange={onSelectProduct}
                             paginator rows={25}
                             emptyMessage="Ürün Bulunamadı.">
          <Column selectionMode="multiple" headerStyle={{width: '3em'}}/>
          <Column field="subCategory.category.name" header="Kategori"/>
          <Column field="subCategory.name" header="Alt Kategori"/>
          <Column field="brand.name" header="Marka"/>
          <Column field="supplyName" body={supplyTemplate} header="Tedarikçi"/>
          <Column field="name" body={modelTemplate} header="Ürün İsmi"/>
          <Column field="requestDate" body={dateTemplate} header="İstek Tarihi"/>
          <Column style={{width: 130}} field="barcode" header="Barkod"/>
          <Column field="supplyStatus" header="Tedarik Durumu"/>
          <Column field="origin" header="Menşei"/>
          <Column field="startDate" headerStyle={{width: "8%", textAlign: "left"}} body={e => {
            return (<div>
              {moment(e?.startDate).format("DD/MM/YYYY")}
            </div>)
          }} header="Geçerli Olacağı Tarih"/>
          <Column field="distyPrice" body={e=>{
            return(<div>
              {e?.distyPrice}
            </div>)
          }} header="Distribütör Alış Fiyatı" sortable/>
          <Column field="recommendedEdmPrice" body={e=>{
            return(<div>
              {e?.recommendedEdmPrice}
            </div>)
          }} header="Önerilen EDM Alış Fiyatı" sortable/>
          <Column field="recommendedResellerPrice" body={e=>{
            return(<div>
              {e?.recommendedResellerPrice}
            </div>)
          }} header="Önerilen Bayi Alış Fiyatı" sortable/>
          <Column field="recommendedSellPrice" body={e=>{
            return(<div>
              {e?.recommendedSellPrice}
            </div>)
          }} header="Önerilen Satış Fiyatı" sortable/>
          <Column body={e => eolTemplate(e)} header="Eol"/>
          <Column field="pictures" body={pictureTemplate} header="Resimler"/>
          <Column field="currentModelId" body={currentModelTemplate}/>
          <Column body={e => actions(e)}/>

        </ResponsiveDataTable>

      </div>

      <Dialog style={{width: 650, height: 300}} visible={pictureDialog} onHide={e => {
        setPictureDialog(false)
        setApproveDescription("");
      }}>


        {pictureList?.length > 0 ?
          <div><Galleria value={pictureList} responsiveOptions={responsiveOptions} numVisible={1} circular
                         style={{maxWidth: '640px'}}
                         showItemNavigators showThumbnails={false} item={itemTemplate} thumbnail={thumbnailTemplate}/>
          </div> : "Resim Bulunmamaktadır."}
      </Dialog>

      <Dialog visible={dialog} modal style={{width: '500px'}}
              onHide={e => setDialog(false)}>


        {currentModel?.approveType === "APPROVED" ?


          <div className="datatable-responsive-demo">
            <h5>Ürün Değişiklikleri</h5>
            <div className="p-grid">
              <div className="p-field p-col-12">

                {controls.categorySame ? <p className="marginParagraph">
                  <b>Kategori: </b> {currentModel?.subCategory?.category?.name}-->{updatedModel?.subCategory?.category?.name}
                </p> : <p style={{backgroundColor: "lightskyblue"}} className="marginParagraph">
                  <b>Kategori: </b> {currentModel?.subCategory?.category?.name}-->{updatedModel?.subCategory?.category?.name}
                </p>}
                {controls.categorySame ? <p className="marginParagraph">
                  <b>Alt Kategori: </b> {currentModel?.subCategory?.name}-->{updatedModel?.subCategory?.name}
                </p> : <p style={{backgroundColor: "lightskyblue"}} className="marginParagraph">
                  <b>Alt Kategori: </b> {currentModel?.subCategory?.name}-->{updatedModel?.subCategory?.name}
                </p>}
                {controls.brandSame ? <p className="marginParagraph">
                  <b>Marka: </b> {currentModel?.brand?.name}-->{updatedModel?.brand?.name}
                </p> : <p style={{backgroundColor: "lightskyblue"}} className="marginParagraph">
                  <b>Marka: </b> {currentModel?.brand?.name}-->{updatedModel?.brand?.name}
                </p>}

                {controls.subCategorySame ? <p className="marginParagraph">
                  <b>Ürün İsmi: </b> {currentModel?.name}-->{updatedModel?.name}
                </p> : <p style={{backgroundColor: "lightskyblue"}} className="marginParagraph">
                  <b>Ürün İsmi: </b> {currentModel?.name}-->{updatedModel?.name}
                </p>}
                {controls.description ? <p className="marginParagraph">
                  <b>Ürün Açıklaması: </b> {currentModel?.description}-->{updatedModel?.description}
                </p> : <p style={{backgroundColor: "lightskyblue"}} className="marginParagraph">
                  <b>Ürün Açıklaması: </b> {currentModel?.description}-->{updatedModel?.description}
                </p>}
                {controls.barcodeName ? <p className="marginParagraph">
                  <b>Barkod: </b> {currentModel?.barcode}-->{updatedModel?.barcode}
                </p> : <p style={{backgroundColor: "lightskyblue"}} className="marginParagraph">
                  <b>Barkod: </b> {currentModel?.barcode}-->{updatedModel?.barcode}
                </p>}

                {controls.supplyStatusSame ? <p className="marginParagraph">
                  <b>Tedarikçi Durumu: </b> {currentModel?.supplyStatus}-->{updatedModel?.supplyStatus}
                </p> : <p style={{backgroundColor: "lightskyblue"}} className="marginParagraph">
                  <b>Tedarikçi Durumu: </b> {currentModel?.supplyStatus}-->{updatedModel?.supplyStatus}
                </p>}

                {controls.originSame ? <p className="marginParagraph">
                  <b>Menşei: </b> {currentModel?.origin}-->{updatedModel?.origin}
                </p> : <p className="marginParagraph" style={{backgroundColor: "lightskyblue"}}>
                  <b>Menşei: </b> {currentModel?.origin}-->{updatedModel?.origin}
                </p>}

                <p className="marginParagraph">
                  <b>Distribütör Alış Fiyatı: </b> {updatedModel?.distyPrice}
                </p>

                <p className="marginParagraph">
                  <b>Önerilen EDM Alış Fiyatı: </b> {updatedModel?.recommendedEdmPrice}
                </p>

                <p className="marginParagraph">
                  <b>Önerilen Bayi Alış Fiyatı: </b> {updatedModel?.recommendedResellerPrice}
                </p>

                <p className="marginParagraph">
                  <b>Önerilen Satış Fiyatı: </b> {updatedModel?.recommendedSellPrice}
                </p>

                {controls.eolSame ? <p className="marginParagraph">
                  <b>EOL: </b> {currentModel?.eol ? "EVET" : "HAYIR"}-->{updatedModel?.eol ? "EVET" : "HAYIR"}
                </p> : <p className="marginParagraph" style={{backgroundColor: "lightskyblue"}}>
                  <b>EOL: </b> {currentModel?.eol ? "EVET" : "HAYIR"}-->{updatedModel?.eol ? "EVET" : "HAYIR"}
                </p>}


                {pictureList?.length > 0 ?
                  <div>
                    <h5>Yeni Resimler</h5>
                    <Galleria value={pictureList} responsiveOptions={responsiveOptions} numVisible={1} circular
                              style={{maxWidth: '640px'}}
                              showItemNavigators showThumbnails={false} item={itemTemplate}
                              thumbnail={thumbnailTemplate}/>
                  </div>
                  : ""}
                <br/>
                <br/>

                {deletePictureList?.length > 0 ? <div><h5>Silmek İstenen Resimler</h5>
                    <Galleria value={deletePictureList} responsiveOptions={responsiveOptions} numVisible={1} circular
                              style={{maxWidth: '640px'}}
                              showItemNavigators showThumbnails={false} item={itemTemplate}
                              thumbnail={thumbnailTemplate}/>
                  </div>
                  : ""}
              </div>
            </div>
          </div> :
          <div className="datatable-responsive-demo">
            <h5>Yeni Ürün Girişi</h5>
            <div className="p-grid">
              <div className="p-field p-col-12">

                <p className="marginParagraph">
                  <b>Kategori: </b>{updatedModel?.subCategory?.category?.name}
                </p>

                <p className="marginParagraph">
                  <b>Alt Kategori: </b>{updatedModel?.subCategory?.name}
                </p>

                <p className="marginParagraph">
                  <b>Marka: </b>{updatedModel?.brand?.name}
                </p>

                <p className="marginParagraph">
                  <b>Ürün İsmi: </b>{updatedModel?.name}
                </p>

                <p className="marginParagraph">
                  <b>Ürün Açıklaması: </b>{updatedModel?.description}
                </p>

                <p className="marginParagraph">
                  <b>Barkod: </b>{updatedModel?.barcode}
                </p>
                <p className="marginParagraph">
                  <b>Tedarikçi Durumu: </b> {updatedModel?.supplyStatus}
                </p>


                <p className="marginParagraph">
                  <b>Menşei: </b>{updatedModel?.origin}
                </p>

                <p className="marginParagraph">
                  <b>EOL: </b>{updatedModel?.eol ? "EVET" : "HAYIR"}
                </p>

                <p className="marginParagraph">
                  <b>Distribütör Alış Fiyatı: </b> {updatedModel?.distyPrice}
                </p>

                <p className="marginParagraph">
                  <b>Önerilen EDM Alış Fiyatı: </b> {updatedModel?.recommendedEdmPrice}
                </p>

                <p className="marginParagraph">
                  <b>Önerilen Bayi Alış Fiyatı: </b> {updatedModel?.recommendedResellerPrice}
                </p>

                <p className="marginParagraph">
                  <b>Önerilen Satış Fiyatı: </b> {updatedModel?.recommendedSellPrice}
                </p>


                {pictureList?.length > 0 ?
                  <div>
                    <h5>Yeni Resimler</h5>
                    <Galleria value={pictureList} responsiveOptions={responsiveOptions} numVisible={1} circular
                              style={{maxWidth: '640px'}}
                              showItemNavigators showThumbnails={false} item={itemTemplate}
                              thumbnail={thumbnailTemplate}/>
                  </div>
                  : ""}
              </div>
            </div>
          </div>
        }
      </Dialog>

      <Dialog style={{width: 650}} onHide={e => {
        setRejectListDialog(false)
        setApproveDescription("");
      }} visible={rejectListDialog}>

        <h5>Açıklama</h5>
        <InputTextarea style={{width: 500}} autoResize id="rejectList" value={approveDescription}
                       onChange={e => setApproveDescription(e.target.value)}/>
        <br/>
        <Button label="Gönder" disabled={!approveList?.length > 0} onClick={e => rejectSubmit(e)}/>
      </Dialog>

      <Dialog style={{width: 650}} onHide={e => {
        setSelectDialog(false);
        setSelectApproveDescription("");
      }}
              visible={selectDialog}>
        <h5>Açıklama</h5>
        <InputTextarea style={{width: 500}} autoResize id="selectApproveDescription" value={selectApproveDescription}
                       onChange={e => setSelectApproveDescription(e.target.value)}/>
        <br/>
        <Button disabled={loading} loading={loading} label="Gönder" onClick={e => rejectModel()}/>


      </Dialog>


    </>
  )
}
export default WaitingMyAction;
