import React, {useEffect, useRef, useState} from "react";
import {Toast} from 'primereact/toast';
import ResellerService from "./httpService";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import {Dialog} from "primereact/dialog";
import {Calendar} from "primereact/calendar";
import {Dropdown} from "primereact/dropdown";

const ResellerScoreTransactions = () => {

  const resellerCode = JSON.parse(localStorage.getItem('user')).user?.loginReseller?.code;
  const toast = useRef(null);
  const resellerService = new ResellerService();

  const [resellerScore, setResellerScore] = useState(null);
  const [resellerScoreWaitingTransactions, setResellerScoreWaitingTransactions] = useState(null);
  const [selectedResellerScoreWaitingTransactions, setSelectedResellerScoreWaitingTransactions] = useState(null);
  const [totolScore, setTotalScore] = useState(0);

  const [waitingTransactionsLoading, setWaitingTransactionsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchCriteriaLazyLoading, setSearchCriteriaLazyLoading] = useState(false);
  const [searchCriteriaProgressing, setSearchCriteriaProgressing] = useState(false);
  const [resellerList, setResellerList] = useState([]);

  const [searchCriteria, setSearchCriteria] = useState({});

  const [detailModal, setDetailModal] = useState(false);
  const [selectedSale, setSelectedSale] = useState(null);
  const [transactionDate, setTransactionDate] = useState(null);
  const [scoreTransactionType, setScoreTransactionType] = useState(null);

  const [transactionsButtonsDisabled, setTransactionsButtonsDisabled] = useState(false);
  const [transactionsIdList, setTransactionsIdList] = useState(false);
  const [transactionType, setTransactionType] = useState(null);

  const [transactionModalHeader, setTransactionModalHeader] = useState(null);
  const [transactionModalMessage, setTransactionModalMessage] = useState(null);
  const [transactionModal, setTransactionModal] = useState(false);

  const [paginationConfig, setPaginationConfig] = useState({
    itemsPerPage: 10,
    first: 0,
    totalRecords: 30,
    activePage: 0,
  });

  const [scoreTransactionTypeOptions, setScoreTransactionTypeOptions] = useState(
    [
      {value: "SCORE_GAIN", name: "Puan Kazancı"},
      {value: "SCORE_APPROVE", name: "Puan Onayı"},
      {value: "UNAPPROVED_SCORE_CANCEL", name: "Onaylanmamış Puan İptali"},
      {value: "APPROVED_SCORE_CANCEL", name: "Onaylanmış Puan İptali"},
      {value: "SCORE_SPEND", name: "Puan Harcaması"}
    ]
  );

  useEffect(() => {
    getResellerScore();
    getResellerScoreWaitingTransactions();
    resellerScoreSearchTransactions({...paginationConfig, activePage: 0});
  }, []);

  useEffect(() => {
    if (selectedResellerScoreWaitingTransactions != null) {
      let score = 0;
      selectedResellerScoreWaitingTransactions.forEach(s => {
        score += s.transactionAmount;
      });
      setTotalScore(score);
    } else {
      setTotalScore(0);
    }
  }, [selectedResellerScoreWaitingTransactions]);

  useEffect(() => {
    setSearchCriteriaLazyLoading(true);
  }, [transactionDate, scoreTransactionType, searchCriteria]);

  useEffect(() => {
    if (searchCriteriaLazyLoading) {
      setTimeout(() => {
        setSearchCriteriaProgressing(true);
        setSearchCriteriaLazyLoading(false);
      }, 2000);
    }
  }, [searchCriteriaLazyLoading]);

  useEffect(() => {
    if (searchCriteriaProgressing) {
      resellerScoreSearchTransactions({...paginationConfig, activePage: 0});
      setSearchCriteriaProgressing(false);
    }
  }, [searchCriteriaProgressing]);

  const getResellerScore = () => {
    resellerService.resellerScore(resellerCode).then(res => {
      console.log("resellerScore", res);
      setResellerScore(res);
    }).catch(err => {
      console.log(err);
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Bayi puan bilgileri alınamadı.'
      });
    })
  };

  const getResellerScoreWaitingTransactions = () => {
    setWaitingTransactionsLoading(true);
    resellerService.resellerScoreWaitingTransactions(resellerCode).then(res => {
      console.log("resellerScoreWaitingTransactions", res);
      setResellerScoreWaitingTransactions(res);
    }).catch(err => {
      console.log(err);
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Bayi onay bekleyen işlemler alınamadı.'
      });
    }).finally(() => {
      setWaitingTransactionsLoading(false);
    });
  };

  const resellerScoreSearchTransactions = (pageState) => {
    setLoading(true);
    var sort = "";
    searchCriteria.resellerCode = resellerCode;
    if (searchCriteria.sortField != null && searchCriteria.sortOrder != null) {
      sort += searchCriteria.sortField;
      if (searchCriteria.sortOrder == 1) {
        sort += ",asc";
      } else {
        sort += ",desc";
      }
    } else {
      sort += "createDateTime,desc";
    }

    if (transactionDate != null) {
      searchCriteria.transactionDate = formatDate(transactionDate);
    } else {
      searchCriteria.transactionDate = null;
    }
    searchCriteria.scoreTransactionType = scoreTransactionType;

    resellerService.resellerScoreSearchTransactions(searchCriteria, pageState.itemsPerPage, pageState.activePage, sort).then(res => {
      console.log("resellerScoreSearchTransactions", res.content);
      if (res.content && res.content.length > 0) {
        setResellerList(res.content);

        pageState.totalRecords = res.totalElements;
        pageState.first = pageState.itemsPerPage * pageState.activePage;
        setPaginationConfig({
          ...pageState,
        });
      } else {
        setResellerList([]);
      }
    }).catch(err => {
      toast?.current?.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Satış bilgisi getirme başarısız.'
      });
      console.log(err);
    }).finally(() => {
      setLoading(false);
    });
  };

  const approveWaitingTransactions = () => {
    if (selectedResellerScoreWaitingTransactions != null && selectedResellerScoreWaitingTransactions.length > 0) {
      var idList = [];
      selectedResellerScoreWaitingTransactions.forEach(k => {
        idList.push(k.id);
      });
      setTransactionsIdList(idList);
      setTransactionType("approve");
      setTransactionModalHeader("İşlemleri Onaylama");
      setTransactionModalMessage(selectedResellerScoreWaitingTransactions?.length + " tane işlemi onaylamak istediğinizden emin misiniz?");
      setTransactionModal(true);
    } else {
      toast.current.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'En az bir işlem seçili olmalıdır.'
      });
    }
  };

  const cancelWaitingTransactions = () => {
    if (selectedResellerScoreWaitingTransactions != null && selectedResellerScoreWaitingTransactions.length > 0) {
      var idList = [];
      selectedResellerScoreWaitingTransactions.forEach(k => {
        idList.push(k.id);
      });
      setTransactionsIdList(idList);
      setTransactionType("cancel");
      setTransactionModalHeader("İşlemleri İptal Etme");
      setTransactionModalMessage(selectedResellerScoreWaitingTransactions?.length + " tane işlemi iptal etmek istediğinizden emin misiniz? Satış işlemlerinin iadesi gerçekleştirilecektir.");
      setTransactionModal(true);
    } else {
      toast.current.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'En az bir işlem seçili olmalıdır.'
      });
    }
  };

  const transactionProgress = () => {
    if (transactionType === "approve") {
      setTransactionsButtonsDisabled(true);
      const request = {
        resellerCode: resellerCode,
        transactionsIdList: transactionsIdList
      };
      resellerService.resellerScoreApproveWaitingTransactions(request).then(res => {
        console.log("resellerScoreApproveWaitingTransactions", res);
        setSelectedResellerScoreWaitingTransactions(null);
        getResellerScore();
        getResellerScoreWaitingTransactions();
        resellerScoreSearchTransactions({...paginationConfig, activePage: 0});
        toast.current.show({
          severity: 'success',
          summary: 'Başarılı İşlem',
          detail: 'İşlemler onaylandı.'
        });
      }).catch(err => {
        console.log(err);
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: err.response.data.message,
          life: 4000
        });
      }).finally(() => {
        setTransactionModal(false);
        setTransactionsButtonsDisabled(false);
      });
    } else if (transactionType === "cancel") {
      setTransactionsButtonsDisabled(true);
      const request = {
        resellerCode: resellerCode,
        transactionsIdList: transactionsIdList
      };
      resellerService.resellerScoreCancelWaitingTransactions(request).then(res => {
        console.log("resellerScoreCancelWaitingTransactions", res);
        setSelectedResellerScoreWaitingTransactions(null);
        getResellerScore();
        getResellerScoreWaitingTransactions();
        resellerScoreSearchTransactions({...paginationConfig, activePage: 0});
        toast.current.show({
          severity: 'success',
          summary: 'Başarılı İşlem',
          detail: 'İşlemler iptal için onaya gönderildi.'
        });
      }).catch(err => {
        console.log(err);
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'İşlemler iptal için onaya gönderilemedi.'
        });
      }).finally(() => {
        setTransactionModal(false);
        setTransactionsButtonsDisabled(false);
      });
    }
  };

  const transactionModalHide = () => {
    setTransactionModal(false);
  };

  const renderFooter = () => {
    return (
      <div>
        <Button label="Hayır" icon="pi pi-times" onClick={transactionModalHide} className="p-button-text"/>
        <Button label="Evet" icon="pi pi-check" onClick={transactionProgress} autoFocus/>
      </div>
    );
  };

  const onActivePageChange = (e) => {
    let pageState = {
      ...paginationConfig,
      itemsPerPage: e.rows,
      activePage: e.page,
    };
    resellerScoreSearchTransactions(pageState);
  };

  const onFilter = (e) => {
    let _lazyParams = {...searchCriteria, ...e};
    setSearchCriteria(_lazyParams);
  };

  const onSort = (e) => {
    let _lazyParams = {...searchCriteria, ...e};
    setSearchCriteria(_lazyParams);
  };

  const saleDetailModalShow = (e) => {
    setDetailModal(true);
    resellerScoreWaitingTransactions.forEach(s => {
      if (s.id == e.currentTarget.id) {
        setSelectedSale(s.productSaleDto);
      }
    });
  };

  const detaillModalHide = () => {
    setDetailModal(false);
  };

  const onTransactionDateChange = (e) => {
    setTransactionDate(e.value);
  };

  const filterDate = (value, filter) => {
    if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
      return true;
    }

    if (value === undefined || value === null) {
      return false;
    }

    return value === formatDate(filter);
  };

  const formatDate = (date) => {
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = '0' + month;
    }

    if (day < 10) {
      day = '0' + day;
    }

    return day + '.' + month + '.' + date.getFullYear();
  };

  const transactionDateFilter = <Calendar value={transactionDate} onChange={onTransactionDateChange}
                                          dateFormat="dd.mm.yy"
                                          className="p-column-filter" showButtonBar/>;

  const addCommas = num => num?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const detailButtonTemplate = (data) => {
    return (
      <div className="p-d-flex p-justify-even">
        <Button id={data.id} type="button" className="p-button-sm" label="Detay"
                onClick={saleDetailModalShow}/>
      </div>
    );
  };

  const responsiveColumn = (title, content) => {
    return (
      <div>
        <div className="p-column-title">{title}</div>
        {content}
      </div>
    );
  };

  const responsiveColumnRight = (title, content) => {
    return (
      <div>
        <span className="p-column-title">{title}</span>
        <span style={{float: 'right'}}>{content}</span>
      </div>
    );
  };

  const saleDateTemplate = (data) => {
    return responsiveColumn("Satış Tarihi", data?.productSaleDto?.completedDateTimeDisplay)
  };

  const vatIncludedPriceTemplate = (data) => {
    return responsiveColumnRight("Satış Fiyatı", addCommas(data?.productSaleDto?.finalPrice))
  };

  const remainScoreTemplate = (data) => {
    return responsiveColumnRight("Puan", addCommas(data?.remainScore))
  };

  const transactionAmountTemplate = (data) => {
    return responsiveColumnRight("Puan", addCommas(data?.transactionAmount))
  };

  const latestApprovedScoreTemplate = (data) => {
    return responsiveColumnRight("Onaylanmış Puan Son Durum", addCommas(data?.latestApprovedScore))
  };

  const latestUnapprovedScoreTemplate = (data) => {
    return responsiveColumnRight("Onaylanmamış Puan Son Durum", addCommas(data?.latestUnapprovedScore))
  };

  const createDateTimeTemplate = (data) => {
    return responsiveColumn("İşlem Tarihi", data?.createDateTimeString)
  };

  const scoreTransactionTypeDisplayTemplate = (data) => {
    return responsiveColumn("İşlem Tipi", data?.scoreTransactionTypeDisplay)
  };

  const barcodeTemplate = (data) => {
    return responsiveColumn("Barkod", data?.barcode)
  };

  const serialNoTemplate = (data) => {
    return responsiveColumn("Seri Numara", data?.serialNo)
  };

  const brandTemplate = (data) => {
    return responsiveColumn("Marka", data?.model?.brand?.name)
  };

  const modelTemplate = (data) => {
    return responsiveColumn("Model", data?.model?.name)
  };

  const saleStatusDisplayTemplate = (data) => {
    return responsiveColumn("Satış Durumu", data?.saleStatusDisplay)
  };

  const priceTemplate = (data) => {
    return responsiveColumnRight("Tavsiye Edilen Satış Fiyatı", addCommas(data?.recommendedSellPrice))
  };

  const renderScoreTransactionTypeFilterElement = () => {
    return (
      <Dropdown value={scoreTransactionType} options={scoreTransactionTypeOptions} onChange={(e) => setScoreTransactionType(e.value)}
                optionLabel="name" showClear className="p-column-filter"/>
    );
  };

  const scoreTransactionTypeFilterElement = renderScoreTransactionTypeFilterElement();

  return (
    <>
      <Toast ref={toast}/>
      <div className="datatable-responsive-demo">
        <div className="card p-grid">
          <div className="p-col-12">
            <p><b>Bayi Kodu: </b>{resellerCode}</p>
            <p><b>Bayi Onaylanmış Puan: </b>{addCommas(resellerScore?.approvedScore)}</p>
            <p><b>Bayi Onaylanmamış Puan: </b>{addCommas(resellerScore?.unapprovedScore)}</p>
          </div>
          <div className="p-col-12 p-md-8">
            <ResponsiveDataTable
              header="Onay Bekleyen İşlem Listesi"
              value={resellerScoreWaitingTransactions}
              loading={waitingTransactionsLoading}
              selection={selectedResellerScoreWaitingTransactions}
              onSelectionChange={e => setSelectedResellerScoreWaitingTransactions(e.value)}
              paginator rows={5} rowsPerPageOptions={[5, 10, 15]}
              paginatorTemplate="FirstPageLink PageLinks LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{totalRecords} kayıttan {first} - {last} arası gösteriliyor"
              emptyMessage="Onay bekleyen işlem bulunamadı"
            >
              <Column selectionMode="multiple" headerStyle={{width: '3em'}}/>
              <Column field="productSaleDto.completedDateTimeDisplay" header="Satış Tarihi" sortable filter={true}
                      filterMatchMode={"contains"}
                      body={saleDateTemplate}/>
              <Column field="productSaleDto.finalPrice" header="Satış Fiyatı" filter={true} filterMatchMode={"contains"}
                      sortable
                      body={vatIncludedPriceTemplate} headerStyle={{textAlign: 'right'}}/>
              <Column field="transactionAmount" header="Puan" filter={true} filterMatchMode={"contains"}
                      sortable
                      body={remainScoreTemplate} headerStyle={{textAlign: 'right'}}/>
              <Column body={detailButtonTemplate} headerStyle={{width: "12%", textAlign: "center"}}
                      bodyStyle={{textAlign: 'center', overflow: 'visible', justifyContent: 'center'}}/>
            </ResponsiveDataTable>
          </div>
          <div className="p-col-12 p-md-4">
            <p><b>Seçili İşlem
              Sayısı: </b>{selectedResellerScoreWaitingTransactions != null ? selectedResellerScoreWaitingTransactions.length : 0}
            </p>
            <p><b>Toplam Puan: </b>{addCommas(totolScore)}</p>
            <Button disabled={transactionsButtonsDisabled || totolScore == 0}
                    style={{marginRight: 5}} className="p-button-success" icon="pi pi-check" label="Onayla"
                    onClick={approveWaitingTransactions}/>
            <Button disabled={transactionsButtonsDisabled || totolScore == 0}
                    className="p-button-danger" icon="pi pi-times" label="İptal Et"
                    onClick={cancelWaitingTransactions}/>
          </div>
          <div className="p-col-12">
            <ResponsiveDataTable
              lazy
              style={{marginTop: 20}}
              header="Bütün Puan İşlemleri"
              value={resellerList}
              paginationConfig={paginationConfig}
              onActivePageChange={onActivePageChange}
              onFilter={onFilter}
              filters={searchCriteria.filters}
              onSort={onSort}
              sortField={searchCriteria.sortField}
              sortOrder={searchCriteria.sortOrder}
              loading={loading}
              emptyMessage="Puan işlemi bulunamadı"
            >
              <Column field="createDateTime" body={createDateTimeTemplate} header="İşlem Tarihi" filter={true}
                      filterElement={transactionDateFilter}
                      filterFunction={filterDate} sortable/>
              <Column field="scoreTransactionType" body={scoreTransactionTypeDisplayTemplate} header="İşlem Tipi" filter={true}
                      filterElement={scoreTransactionTypeFilterElement}/>
              <Column field="transactionAmount" header="Puan" sortable
                      body={transactionAmountTemplate} headerStyle={{textAlign: 'right'}}/>
              <Column field="latestApprovedScore" header="Onaylanmış Puan Son Durum"
                      body={latestApprovedScoreTemplate} headerStyle={{textAlign: 'right'}}/>
              <Column field="latestUnapprovedScore" header="Onaylanmamış Puan Son Durum"
                      body={latestUnapprovedScoreTemplate} headerStyle={{textAlign: 'right'}}/>
            </ResponsiveDataTable>
          </div>
        </div>
        <Dialog header="Satış Detay" visible={detailModal} modal style={{width: '1000px'}}
                onHide={detaillModalHide}>
          <div className="datatable-responsive-demo">
            <div className="p-grid">
              <div className="p-field p-col-12">
                <p className="marginParagraph"><b>Satış Tarihi:</b> {selectedSale?.completedDateTimeDisplay}</p>
                <p className="marginParagraph"><b>Satış Tipi:</b> {selectedSale?.productSaleTypeDisplay}</p>
                <p className="marginParagraph"><b>Satış Durumu:</b> {selectedSale?.productSaleStatusDisplay}</p>
                <p className="marginParagraph"><b>Bayi Kodu:</b> {selectedSale?.reseller?.code}</p>
                <p className="marginParagraph"><b>İşlemi Yapan Ad:</b> {selectedSale?.sellerUser?.name}</p>
                <p className="marginParagraph"><b>İşlemi Yapan Soyad:</b> {selectedSale?.sellerUser?.surname}</p>
                <p className="marginParagraph"><b>Satış Fiyatı:</b> {addCommas(selectedSale?.finalPrice)}</p>
                {selectedSale?.discountAmount != null &&
                <p className="marginParagraph"><b>İskonto Tutarı:</b> {addCommas(selectedSale?.discountAmount)}</p>}
                {selectedSale?.accessoryCampaign &&
                <p className="marginParagraph"><b>Seçilen Kampanya:</b> {selectedSale?.accessoryCampaign?.description}
                </p>}
                {selectedSale?.accessoryCampaignSerial &&
                <p className="marginParagraph"><b>Kampanya
                  Seri:</b> {selectedSale?.accessoryCampaignSerial?.serialValue}
                </p>}
                {selectedSale?.accessoryCampaignPass &&
                <p className="marginParagraph"><b>Kampanya Şifre:</b> {selectedSale?.accessoryCampaignPass?.passValue}
                </p>}
                {selectedSale?.customerName &&
                <p className="marginParagraph"><b>Müşteri Adı:</b> {selectedSale?.customerName}</p>}
                {selectedSale?.customerSurname &&
                <p className="marginParagraph"><b>Müşteri Soyadı:</b> {selectedSale?.customerSurname}</p>}
                {selectedSale?.customerPhoneNo &&
                <p className="marginParagraph"><b>Müşteri Telefon Numarası:</b> {selectedSale?.customerPhoneNo}</p>}
                {selectedSale?.customerServiceNo &&
                <p className="marginParagraph"><b>Müşteri Hizmet No:</b> {selectedSale?.customerServiceNo}</p>}
                {selectedSale?.customerPstnNo &&
                <p className="marginParagraph"><b>Müşteri PSTN No:</b> {selectedSale?.customerPstnNo}</p>}
                {selectedSale?.scoreTransactionAmount != null &&
                <p className="marginParagraph"><b>Kazanılan Puan:</b> {addCommas(selectedSale?.scoreTransactionAmount)}</p>}
                <ResponsiveDataTable value={selectedSale?.productSerialNoList}
                                     header="Cihaz Bigileri"
                                     style={{fontSize: '13px', marginTop: 10}}
                                     paginator rows={5} rowsPerPageOptions={[5, 10, 15]}
                                     paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                     currentPageReportTemplate="{totalRecords} kayıttan {first} - {last} arası gösteriliyor">
                  <Column body={barcodeTemplate} header="Barkod"/>
                  <Column body={serialNoTemplate} header="Seri Numara"/>
                  <Column body={brandTemplate} header="Marka"/>
                  <Column body={modelTemplate} header="Model"/>
                  <Column body={saleStatusDisplayTemplate} header="Satış Durumu"/>
                  <Column body={priceTemplate} header="Tavsiye Edilen Satış Fiyatı" headerStyle={{textAlign: 'right'}}/>
                </ResponsiveDataTable>
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog header={transactionModalHeader} visible={transactionModal} modal style={{width: '400px'}}
                footer={renderFooter('displayConfirmation')} onHide={transactionModalHide}>
          <div className="confirmation-content">
            <span>{transactionModalMessage}</span>
          </div>
        </Dialog>
      </div>
    </>
  )

};

export default ResellerScoreTransactions;
